import appConstant from "../constants/appConstant";
import { v4 as uuidv4 } from "uuid";

export const getNestedValue = (path, obj, defaultVal = null) =>
    path.split(".").reduce((acc, x) => (acc && acc[x] !== undefined && acc[x] !== null ? acc[x] : defaultVal), obj);

export const getNestedArrayLength = (path, obj) => (getNestedValue(path, obj) || []).length || 0;

export const isArray = (obj) => {
    return obj && obj.constructor === [].constructor;
};

export const isJson = (obj) => {
    return obj && obj.constructor === {}.constructor;
};

export const isJsonEmpty = (json) => {
    return json && json.constructor === Object && Object.keys(json).length === 0;
};

export const getPath = () => {
    if (window) {
        const location = getNestedValue("location.href", window);
        return location && (location.split(/\?/)[0] || "");
    } else {
        return "";
    }
};

export const getQueryString = () => {
    if (window) {
        const location = getNestedValue("location.href", window);
        return (location && location.split(/\?/)[1]) || "";
    } else {
        return "";
    }
};

export const getParamFromURL = (paramName, url) => {
    const regex = new RegExp(`[\\?&]${paramName.replace(/([[\]])/g, "\\$1")}=([^&#]*)`);
    const results = regex.exec(url);

    return (results && results[1]) || null;
};

export const getCurrencyPrefix = (countryCode) => {
    const isCountryCodeCanada = countryCode === appConstant.COUNTRY_CODE_CANADA;
    const currencySign = isCountryCodeCanada
        ? `${appConstant.CANADA_SIGN}${appConstant.DOLLAR_SIGN}`
        : appConstant.DOLLAR_SIGN;
    return currencySign;
};

export const scrubEmail = (text) => {
    const scrubText = "****.***";
    const regex = /(%40|@)+((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;

    return text ? text.replace(regex, scrubText) : text;
};

export const getEnvVar = (val) => {
    return window?._env_?.[val];
};

export const normalizeZipCode = (zipCode) => {
    const regex = new RegExp(appConstant.WHITE_SPACE_EXPRESSION);
    return zipCode.replace(regex, "").trim();
};

export const generateTransactionId = () => {
    return `${appConstant.SOURCE_TYPE}_${uuidv4()}`;
};

export const isMobile = () => {
    const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
    const isMobile = Boolean(userAgent.match(/iPhone|Android|iPod|Opera Mini|IEMobile/i));
    return isMobile;
};

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (isMobile) {
        document.body.scrollTo({ top: 0, behavior: "smooth" });
    }
};
