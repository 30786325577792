const THEME_DEFAULT = (variables) => {
    return {
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 992,
                lg: 1200,
                xl: 1400,
            },
        },
        palette: {
            primary: {
                light: variables.palettePrimaryLight,
                main: variables.palettePrimaryMain,
                dark: variables.palettePrimaryDark,
                contrastText: variables.palettePrimaryContrastText,
            },
            secondary: {
                light: variables.paletteSecondaryLight,
                main: variables.paletteSecondaryMain,
                dark: variables.paletteSecondaryDark,
                contrastText: variables.paletteSecondaryContrastText,
            },
            error: {
                light: variables.paletteErrorLight,
                main: variables.paletteErrorMain,
                dark: variables.paletteErrorDark,
                contrastText: variables.paletteErrorContrastText,
            },
            warning: {
                light: variables.paletteWarningLight,
                main: variables.paletteWarningMain,
                dark: variables.paletteWarningDark,
                contrastText: variables.paletteWarningContrastText,
            },
            info: {
                light: variables.paletteInfoLight,
                main: variables.paletteInfoMain,
                dark: variables.paletteInfoDark,
                contrastText: variables.paletteInfoContrastText,
            },
            success: {
                light: variables.paletteSuccessLight,
                main: variables.paletteSuccessMain,
                dark: variables.paletteSuccessDark,
                contrastText: variables.paletteSuccessContrastText,
            },
            grey: {
                50: variables.paletteGrey50,
                100: variables.paletteGrey100,
                200: variables.paletteGrey200,
                300: variables.paletteGrey300,
                400: variables.paletteGrey400,
                500: variables.paletteGrey500,
                600: variables.paletteGrey600,
                700: variables.paletteGrey700,
                800: variables.paletteGrey800,
                900: variables.paletteGrey900,
                A100: variables.paletteGreyA100,
                A200: variables.paletteGreyA200,
                A400: variables.paletteGreyA400,
                A700: variables.paletteGreyA700,
            },
            text: {
                primary: variables.paletteTextPrimary,
                secondary: variables.paletteTextSecondary,
                disabled: variables.paletteTextDisabled,
                hint: variables.paletteTextHint,
                icon: variables.paletteTextIcon,
                divider: variables.paletteTextDivider,
            },
            background: {
                paper: variables.paletteBackgroundPaper,
                default: variables.paletteBackgroundDefault,
            },
            action: {
                active: variables.paletteActionActive,
                hover: variables.paletteActionHover,
                hoverOpacity: parseInt(variables.paletteActionOpacity, 10),
                selected: variables.paletteActionSelected,
                selectedOpacity: parseInt(variables.paletteActionSelectedOpacity, 10),
                disabled: variables.paletteActionDisabled,
                disabledBackground: variables.paletteActionDisabledBackground,
                disabledOpacity: parseInt(variables.paletteActionDisabledOpacity, 10),
                focus: variables.paletteActionFocus,
                focusOpacity: parseInt(variables.paletteActionFocusOpacity, 10),
                activatedOpacity: parseInt(variables.paletteActionActivatedOpacity, 10),
            },
        },
        typography: {
            htmlFontSize: parseInt(variables.typographyHtmlFontSize, 10),
            fontFamily: variables.typographyFontFamily,
            fontSize: parseInt(variables.typographyFontSize, 10),
            fontWeightLight: variables.typographyFontWeightLight,
            fontWeightRegular: variables.typographyFontWeightRegular,
            fontWeightMedium: variables.typographyFontWeightMedium,
            fontWeightBold: variables.typographyFontWeightBold,
            h1: {
                fontFamily: variables.typographyH1FontFamily,
                fontWeight: variables.typographyH1FontWeight,
                fontSize: variables.typographyH1FontSize,
                lineHeight: variables.typographyH1LineHeight,
                letterSpacing: variables.typographyH1LetterSpacing,
            },

            h2: {
                fontFamily: variables.typographyH2FontFamily,
                fontWeight: variables.typographyH2FontWeight,
                fontSize: variables.typographyH2FontSize,
                lineHeight: variables.typographyH2LineHeight,
                letterSpacing: variables.typographyH2LetterSpacing,
            },
            h3: {
                fontFamily: variables.typographyH3FontFamily,
                fontWeight: variables.typographyH3FontWeight,
                fontSize: variables.typographyH3FontSize,
                lineHeight: variables.typographyH3LineHeight,
                letterSpacing: variables.typographyH3LetterSpacing,
            },
            h4: {
                fontFamily: variables.typographyH4FontFamily,
                fontWeight: variables.typographyH4FontWeight,
                fontSize: variables.typographyH4FontSize,
                lineHeight: variables.typographyH4LineHeight,
                letterSpacing: variables.typographyH4LetterSpacing,
            },
            h5: {
                fontFamily: variables.typographyH5FontFamily,
                fontWeight: variables.typographyH5FontWeight,
                fontSize: variables.typographyH5FontSize,
                lineHeight: variables.typographyH5LineHeight,
                letterSpacing: variables.typographyH5LetterSpacing,
            },
            h6: {
                fontFamily: variables.typographyH6FontFamily,
                fontWeight: variables.typographyH6FontWeight,
                fontSize: variables.typographyH6FontSize,
                lineHeight: variables.typographyH6LineHeight,
                letterSpacing: variables.typographyH6LetterSpacing,
            },
            subtitle1: {
                fontFamily: variables.typographySubtitle1FontFamily,
                fontWeight: variables.typographySubtitle1FontWeight,
                fontSize: variables.typographySubtitle1FontSize,
                lineHeight: variables.typographySubtitle1LineHeight,
                letterSpacing: variables.typographySubtitle1LetterSpacing,
            },
            subtitle2: {
                fontFamily: variables.typographySubtitle2FontFamily,
                fontWeight: variables.typographySubtitle2FontWeight,
                fontSize: variables.typographySubtitle2FontSize,
                lineHeight: variables.typographySubtitle2LineHeight,
                letterSpacing: variables.typographySubtitle2LetterSpacing,
            },
            body1: {
                fontFamily: variables.typographyBody1FontFamily,
                fontWeight: variables.typographyBody1FontWeight,
                fontSize: variables.typographyBody1FontSize,
                lineHeight: variables.typographyBody1LineHeight,
                letterSpacing: variables.typographyBody1LetterSpacing,
            },
            body2: {
                fontFamily: variables.typographyBody2FontFamily,
                fontWeight: variables.typographyBody2FontWeight,
                fontSize: variables.typographyBody2FontSize,
                lineHeight: variables.typographyBody2LineHeight,
                letterSpacing: variables.typographyBody2LetterSpacing,
            },
            button: {
                fontFamily: variables.typographyButtonFontFamily,
                fontWeight: variables.typographyButtonFontWeight,
                fontSize: variables.typographyButtonFontSize,
                letterSpacing: variables.typographyButtonLetterSpacing,
                textTransform: variables.typographyButtonTextTransform,
            },
            caption: {
                fontFamily: variables.typographyCaptionFontFamily,
                fontWeight: variables.typographyCaptionFontWeight,
                fontSize: variables.typographyCaptionFontSize,
                letterSpacing: variables.typographyCaptionLetterSpacing,
            },
            overline: {
                fontFamily: variables.typographyOverlayFontFamily,
                fontWeight: variables.typographyOverlayFontWeight,
                fontSize: variables.typographyOverlayFontSize,
                letterSpacing: variables.typographyOverlayLetterSpacing,
                textTransform: variables.typographyOverlayTextTransform,
            },
        },
        shape: {
            borderRadius: parseInt(variables.shapeBorderRadius, 10),
        },
        overrides: {
            MuiCheckbox: {
                root: {
                    padding: "0px",
                },
            },
            MuiFormControlLabel: {
                root: {
                    marginLeft: "0px",
                    marginRight: "8px",
                },
            },
            MuiButton: {
                root: {
                    borderRadius: parseInt(variables.muiButtonRootBorderRadius, 10),
                    border: variables.muiButtonRootBorder,
                    fontFamily: variables.typographySubtitle1FontFamily,
                    Height: "48px",
                    "&$disabled": {
                        //opacity: 0.4,
                    },
                    "&:hover": {
                        "&$disabled": {
                            backgroundColor: "none",
                        },
                    },
                },
                containedSizeLarge: {
                    padding: "11px 22px",
                    fontSize: "16px",
                    lineHeight: "normal",
                    fontWeight: "700",
                },
                outlinedSizeLarge: {
                    padding: variables.muiButtonOutlinedSizeLargePadding,
                    fontSize: "16px",
                    lineHeight: "normal",
                    fontWeight: "700",
                },
                contained: {
                    backgroundColor: "#4DC4B1",
                    border: "2px solid #000000",
                    color: "#000000",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: "#4DC4B1",
                        "&$disabled": {
                            backgroundColor: "#FFFFFF",
                        },
                    },
                    "@media (hover: none)": {
                        "&:hover": {
                            boxShadow: "none !important",
                        },
                    },
                    "&:not(:first-child)": {
                        border: "0",
                    },
                    "&:not(:last-child)": {
                        border: "0",
                    },
                    "&$disabled": {
                        backgroundColor: "#FFFFFF",
                        border: "2px solid #000000",
                        color: "#000000",
                    },
                },
                containedSecondary: {
                    backgroundColor: "#5757F2",
                    color: "#FFFFFF",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: "#5757F2",
                        "&$disabled": {
                            backgroundColor: "#C9C7C7",
                        },
                    },
                    "&:not(:first-child)": {
                        border: "0",
                    },
                    "&:not(:last-child)": {
                        border: "0",
                    },
                    "&$disabled": {
                        backgroundColor: "#C9C7C7",
                        color: "#FFFFFF",
                    },
                },
            },
            MuiButtonGroup: {
                contained: {
                    border: "1px solid #DCD7F2",
                    borderRadius: "16px",
                },
            },
            MuiDialogContent: {
                root: {
                    "overflow-y": "hidden",
                    padding: "8px 12px",
                },
            },
            MuiFormLabel: {
                root: {
                    color: "#515151",
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "DM Sans",
                    paddingLeft: "8px",
                    "&$focused": {
                        color: variables.muiFormLabelRootFocusColor,
                        zIndex: "1201",
                    },
                    "&$error": {
                        color: variables.muiFormLabelRootFocusColor,
                    },
                },
            },
            MuiInputLabel: {
                shrink: {
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#515151",
                    fontFamily: "DM Sans",
                },
            },
            MuiFilledInput: {
                root: {
                    borderRadius: parseInt(variables.muiFilledInputRootBorderRadius, 10),
                    borderTopLeftRadius: variables.muiFilledInputRootBorderTopLeftRadius,
                    borderTopRightRadius: variables.muiFilledInputRootBorderTopRightRadius,
                    border: "2px solid #C9C7C7",
                    backgroundColor: variables.muiFilledInputRootBackgroundColor,
                    color: variables.muiFilledInputRootColor,
                    fontSize: variables.muiFilledInputRootFontSize,
                    fontWeight: variables.muiFilledInputRootFontWeight,
                    fontFamily: variables.muiFilledInputRootFontFamily,

                    "&:hover": {
                        backgroundColor: "#FFFFFF",
                    },
                    "@media (hover: none)": {
                        "&:hover": {
                            backgroundColor: "#FFFFFF !important",
                        },
                    },
                    "&$focused": {
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                        border: "2px solid #5757F2",
                        zIndex: "1200",
                    },
                    "&$error": {
                        border: "2px solid #E51E42",
                    },
                },
                input: {
                    padding: "24px 16px 8px 16px",
                    fontFamily: variables.muiFilledInputRootFontFamily,
                    color: "#000000",
                    fontSize: variables.muiFilledInputRootFontSize,
                    fontWeight: variables.muiFilledInputRootFontWeight,
                    "&::placeholder": {
                        fontFamily: "DM Sans",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#515151",
                        opacity: "1",
                    },
                    "&::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                    },
                    "&::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                    },
                    "&[type=number]": {
                        "-moz-appearance": "textfield",
                    },
                },
            },
            MuiToggleButton: {
                root: {
                    borderTopRightRadius: variables.muiToggleButtonRootBorderTopRightRadius,
                    borderBottomLeftRadius: variables.muiToggleButtonRootBorderBottomLeftRadius,
                    borderTopLeftRadius: variables.muiFilledInputRootBorderTopLeftRadius,
                    borderBottomRightRadius: variables.muiFilledInputRootBorderBottomRightRadius,
                    backgroundColor: variables.muiToggleButtonRootBackgroundColor,
                    "&:hover": {
                        backgroundColor: "#fff",
                    },
                    "&:focused": {
                        backgroundColor: "#fff",
                        boxShadow: "0px 10px 40px rgba(20,34,117,.15)",
                        border: "1px solid #191C4F",
                    },
                },
            },
            MuiToggleButtonGroup: {
                root: {
                    display: "flex",
                },
                groupedHorizontal: {
                    "&:not(:last-child)": {
                        borderTopRightRadius: "16px",
                        borderTopLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        fontSize: "20px",
                        fontWeight: "500",
                        minHeight: "113px",
                    },
                    "&:not(:first-child)": {
                        borderBottomLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                        borderBottomRightRadius: "16px",
                        borderTopLeftRadius: "16px",
                    },
                },
            },
            MuiDialog: {
                paper: {
                    borderBottomLeftRadius: "9px",
                    borderTopRightRadius: "9px",
                    borderBottomRightRadius: "9px",
                    borderTopLeftRadius: "9px",
                    backgroundColor: variables.muiDialogPaperBackgroundColor,
                    padding: variables.muiDialogPaperPadding,
                },
                paperWidthXs: {
                    width: "800px",
                },
            },
            MuiAppBar: {
                colorDefault: {
                    backgroundColor: "#f2f1f6",
                },
            },
            MuiMenuItem: {
                root: {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000",
                    padding: "7px 16px",
                    margin: "0",
                    "&:hover": {
                        backgroundColor: "#fff",
                    },
                    "&$focused": {
                        backgroundColor: "#fff",
                    },
                    "&:last-child": {
                        border: "0",
                    },
                    "@media (min-width:600px)": {},
                },
            },
            MuiPopover: {
                root: {
                    "&$focused": {
                        zIndex: "1200",
                    },
                },
            },
            MuiAutocomplete: {
                popper: {
                    zIndex: "1150",
                },
                paper: {
                    marginTop: "-30px",
                    borderBottomRightRadius: "16px",
                    borderBottomLeftRadius: "16px",
                },

                listbox: {
                    paddingTop: "30px",
                    paddingBottom: "0",
                },
                option: {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000",
                    padding: "7px 16px",
                    margin: "0",

                    "&$focused": {
                        backgroundColor: "#fff",
                    },
                    "&:last-child": {
                        border: "0",
                    },
                    "@media (min-width:600px)": {},
                },
            },
            MuiCard: {
                root: {
                    backgroundColor: "#f2f1f6",
                    borderTopLeftRadius: "16px",
                    borderBottomRightRadius: "16px",
                },
            },
            MuiIconButton: {
                root: {
                    color: "#515151",
                },
            },
        },
        zIndex: {
            mobileStepper: 1000,
            speedDial: 1050,
            appBar: 1250,
            drawer: 13000,
            modal: 13000,
            snackbar: 1400,
            tooltip: 1500,
        },
    };
};

export default THEME_DEFAULT;
