import { getEnvVar } from "common/utilities/ generic";

const URL_PRE = getEnvVar("REACT_APP_PLATFORM_URL_PRE");

const restEndPointURLs = {
    GET_CUSTOMER_EXIST: "fdp-customer-service/v1/customers/noauth",
    SETUP_INTENT: "fdp-wallet-service/v2/wallet/guest/payment-method?service-code={{countryCode}}",
    GET_CUSTOMER_V3: "fdp-customer-service/v3/customers",
    CONFIRM_INTENT: "fdp-wallet-service/v2/wallet/guest/payment-method/{{intentId}}?service-code={{countryCode}}",
    GET_QUOTE: "fdp-microtransaction-service/v1/quotes",
    CHARGE_API: "fdp-microtransaction-service/v1/create-buy",
};

Object.keys(restEndPointURLs).map((key) => (restEndPointURLs[key] = `/${URL_PRE}/${restEndPointURLs[key]}`));

export default restEndPointURLs;
