import appConstant from "common/constants/appConstant";
import ROUTE_CONSTANTS from "common/constants/route";
import HealthReportApp from "components";
import HealthReportPurchase from "components/health-report-purchase";
import AcceptAndContinue from "components/health-report-purchase/acceptAndContinue";
import Error from "../components/error";

const appRoutes = [
    {
        path: ROUTE_CONSTANTS.ROOT,
        component: HealthReportApp,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
    {
        path: ROUTE_CONSTANTS.HEALTH_REPORT_PURCHASE,
        component: HealthReportPurchase,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
    {
        path: ROUTE_CONSTANTS.FREE_HEALTH_REPORT_PURCHASE,
        component: AcceptAndContinue,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
    {
        path: ROUTE_CONSTANTS.ERROR,
        component: Error,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
];

export default appRoutes;
