import appConstant from "common/constants/appConstant";
import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";

const preparePath = () => restEndpoints.CHARGE_API;

const _prepareRequestConfig = (Authorization) => {
    return {
        headers: {
            Authorization,
        },
    };
};

const _preparePayload = (data) => {
    const payloadData = {
        microtransactionType: appConstant.SOURCE_API,
        customerReference: data.externalReferenceId,
        money: {
            amount: Number(data.amount),
            currency: data.currency,
        },
        paymentMethodId: data?.payMethodId,
        walletId: data?.walletId,
        appId: appConstant.APP_ID,
        reportId: data.reportId,
    };

    return payloadData;
};

class CreateBuyApi extends BaseRestApi.Api {
    constructor() {
        super({
            getPath: preparePath,
            method: BaseRestApi.methods.POST,
            getRequestConfig: (Authorization, data) => _prepareRequestConfig(Authorization),
            getRequestPayload: (Authorization, data) => _preparePayload(data),
        });
    }
}

export default new CreateBuyApi();
