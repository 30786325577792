/**
 * The file contents for the theme constants.
 * All the theme style guide related constants must be place here.
 */

import THEME_DEFAULT from "./default/default";

const THEME_CONSTANTS = {
    DEFAULT: THEME_DEFAULT,
};

export default THEME_CONSTANTS;
