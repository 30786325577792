import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";
import appConstant from "../../../common/constants/appConstant";

const _preparePath = (intentId, country) => {
    return restEndpoints.CONFIRM_INTENT.replace("{{intentId}}", intentId).replace("{{countryCode}}", country);
};

const _prepareRequestConfig = (Authorization) => {
    return {
        headers: {
            Authorization,
        },
    };
};
const _preparePayload = ({ prospectId = null, confirmSetupResponse = {}, nameOnCard = null }) => {
    return {
        application: {
            name: appConstant.APP_ID,
            identifier: appConstant.APP_ID,
        },
        product: {
            type: "insurance",
            identifier: "Comprehensive_PetInsurance",
        },
        paymentType: "CARD",
        confirmSetupResponse,
        externalReferenceId: prospectId,
        externalReferenceType: appConstant.CUSTOMER_REFERENCE,
        appId: appConstant.APP_ID,
        nameOnCard: nameOnCard,
    };
};

class ConfirmIntentApi extends BaseRestApi.Api {
    constructor(Authorization, { prospectId, confirmSetupResponse, intentId, country, nameOnCard }) {
        super({
            getPath: () => _preparePath(intentId, country),
            method: BaseRestApi.methods.PUT,
            getRequestConfig: () => _prepareRequestConfig(Authorization),
            getRequestPayload: () => _preparePayload({ prospectId, confirmSetupResponse, nameOnCard }),
        });
    }
}

export default ConfirmIntentApi;
