import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import SetupIntentApi from "services/rest/SetupIntentApi";
import { get } from "lodash";
import ROUTE_CONSTANTS from "common/constants/route";
import logger from "common/utilities/logger";
import { sendPostMessage } from "common/utilities/eventUtil";
import PaymentCardDetails from "../paymentCardDetails";

const PaymentDetails = ({
    setPaymentKeys,
    paymentKeys,
    authorizationSetUpIntent,
    countryCode,
    customerId,
    setError,
    isError,
    isApiError,
    setIsApiError,
    refreshComponent,
    formData,
    setFormData,
    isLoading,
    setIsLoading,
}) => {
    let history = useHistory();

    const setupIntent = new SetupIntentApi(authorizationSetUpIntent, customerId, countryCode);

    const stripePromise = useMemo(
        () =>
            setupIntent
                .invoke()
                .then((resp) => {
                    const { payload } = resp;

                    const { publicKey, secret } = get(payload, "payload.response.keys", {});

                    setPaymentKeys({ publicKey, secret });

                    logger.info("setup intent API call successfull");

                    return loadStripe(publicKey);
                })
                .catch((error) => {
                    if (error?.error?.code === 401 || error?.error?.code === 403) {
                        logger.error("session expired in setup intent API call", error);

                        setTimeout(() => {
                            sendPostMessage("healthReportPurchase-sdk-session-expired");
                        }, 100);
                    } else {
                        logger.error("Error in setup intent API call", error);

                        history.push(ROUTE_CONSTANTS.ERROR);
                    }

                    return loadStripe(null);
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authorizationSetUpIntent]
    );

    return (
        <>
            {stripePromise && (
                <Elements stripe={stripePromise}>
                    {paymentKeys.publicKey && (
                        <PaymentCardDetails
                            paymentKeys={paymentKeys}
                            customerId={customerId}
                            setError={setError}
                            isError={isError}
                            isApiError={isApiError}
                            setIsApiError={setIsApiError}
                            refreshComponent={refreshComponent}
                            formData={formData}
                            setFormData={setFormData}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    )}
                </Elements>
            )}
        </>
    );
};

export default PaymentDetails;

PaymentDetails.propTypes = {
    setPaymentKeys: PropTypes.func,
    paymentKeys: PropTypes.object,
    authorizationSetUpIntent: PropTypes.string,
    countryCode: PropTypes.string,
    customerId: PropTypes.string,
    isCardValeChange: PropTypes.number,
    setCardChangeValue: PropTypes.func,
    setError: PropTypes.func,
    isError: PropTypes.bool,
    setIsApiError: PropTypes.func,
    isApiError: PropTypes.bool,
    refreshComponent: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
};
