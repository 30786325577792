import appConstant from "common/constants/appConstant";
import { getEnvVar } from "common/utilities/ generic";
import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";

const preparePath = () => restEndpoints.GET_QUOTE;

const _prepareRequestConfig = (Authorization) => {
    return {
        headers: {
            Authorization,
        },
    };
};

const _preparePayload = (data) => {
    const payloadData = {
        ...(data.promoCode && {
            promoCode: data.promoCode,
        }),
        microtransactionType: appConstant.SOURCE_API,
        customerReference: data.customerId,
        email: data.emailId,
        petName: data.petName,
        petAge: data.petAge,
        petType: "DOG",
        breedId: data.breedId,
        breedName: data.breedName,
        gender: data.petGender,
        zipCode: data.zipcode,
        state: data.state,
        country: data.country,
        reportId: data.reportId,
        policyQuoteUrl:
            getEnvVar("REACT_APP_BUY_QUOTE_URL") + "?prospectId=${prospectId}&prospectPetId=${prospectPetId}",
    };

    return payloadData;
};

class GetQuoteApi extends BaseRestApi.Api {
    constructor() {
        super({
            getPath: preparePath,
            method: BaseRestApi.methods.POST,
            getRequestConfig: (Authorization, data) => _prepareRequestConfig(Authorization),
            getRequestPayload: (Authorization, data) => _preparePayload(data),
        });
    }
}

export default new GetQuoteApi();
