const pageLoadTags = {
    pageType: "page_type",
    pageTitle: "page_title",
    queryString: "query_string",
    userId: "user_id",
    clickId: "CLICK_ID",
    emailAddress: "emailAddress",
};

export default pageLoadTags;
