class Storage {
    local = {
        get: (key) => {
            return JSON.parse(localStorage.getItem(key));
        },
        set: (key, data) => {
            localStorage.setItem(key, JSON.stringify(data));
        },

        remove: (key) => {
            localStorage.removeItem(key);
        },
        removeAll: () => {
            localStorage.clear();
        },
    };

    session = {
        get: (key) => {
            return JSON.parse(sessionStorage.getItem(key));
        },
        set: (key, data) => {
            sessionStorage.setItem(key, JSON.stringify(data));
        },

        remove: (key) => {
            sessionStorage.removeItem(key);
        },
        removeAll: () => {
            sessionStorage.clear();
        },
    };

    cookie = {
        setCookie: (cname, cvalue, exdays) => {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },

        getCookie: (cname) => {
            let name = cname + "=";
            let ca = document.cookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

        deleteCookie: (cname) => {
            document.cookie = `${cname}="";expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        },
    };
}

const storage = new Storage();
export default storage;
