import { isTypeOf } from "./types";
import logger from "./logger";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const PERIOD_IN_MS = {
    TWO_YEARS: 63072000000,
    TWO_HOURS: 7200000,
};

export const getFutureDate = (milliseconds) => {
    if (isTypeOf(milliseconds, "string") || isTypeOf(milliseconds, "number")) {
        if (isTypeOf(milliseconds, "string")) {
            if (PERIOD_IN_MS[milliseconds]) {
                milliseconds = PERIOD_IN_MS[milliseconds];
            } else {
                logger.error(`Invalid period : ${milliseconds}`);
                return;
            }
        }
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + milliseconds);
        return futureDate.toUTCString();
    }
    logger.error(`Invalid type for milliseconds: ${milliseconds}`);
};

export const getCurrentTimeInMs = () => Date.now();

export const formatDateRange = (date) => {
    return dayjs(date, "YYYY-MM-DD").format("MMMM D");
};

export const formatDateRangeSalshFormat = (date) => {
    return dayjs(date, "MM/DD/YYYY").format("MMM D");
};

export const formatDate = (date) => {
    return dayjs(date, "YYYY-MM-DD").format("dddd, MMMM D");
};

export const getDayInfo = () => {
    const date = dayjs();
    const hours = Number(dayjs(date, "YYYY-MM-DD").format("h"));
    const hoursWithAmPm = dayjs(date, "YYYY-MM-DD").format("hhA");
    const AMPM = dayjs(date, "YYYY-MM-DD").format("A");
    const dayInfo = { hours, hoursWithAmPm, AMPM };
    return dayInfo;
};

export const getDay = (date) => {
    return dayjs(date, "YYYY-MM-DD").format("D");
};

export const getDaySlashFormat = (date) => {
    return dayjs(date, "MM/DD/YYYY").format("D");
};

export const dateOrdinal = (day) => {
    return ["st", "nd", "rd"][((((day + 90) % 100) - 10) % 10) - 1] || "th";
};

export const checkExpiryDetails = (date) => {
    const expDate = dayjs(date, "YYYY-MM-DD");
    const currentDate = dayjs();
    const todayDate = dayjs(currentDate, "YYYY-MM-DD");
    const diff = expDate.diff(todayDate, "day");
    let text = "";
    if (diff === 0) text = "today";
    if (diff === 1) text = "tomorrow";
    if (diff < 0) text = "expired";
    return text;
};

export const sortArrayByDate = (array, key, order = "asc") => {
    return (
        array &&
        array.sort((a, b) => {
            const orderSwitch = order === "asc" ? 1 : -1;
            const dateA = dayjs(a[key], "YYYY-MM-DD");
            const dateB = dayjs(b[key], "YYYY-MM-DD");
            const diff = dateA.diff(dateB, "day");
            if (diff > 0) {
                return 1 * orderSwitch;
            }
            if (diff < 0) {
                return -1 * orderSwitch;
            }
            return 0;
        })
    );
};

export const getFormattedDay = () => {
    return dayjs().format("HH:mm:ss");
};

export const getCurrentDay = () => {
    return dayjs().format("dddd");
};

export const getDifferenceInDays = (minDateStr, maxDateStr) => {
    const format = "YYYY-MM-DD";
    const msInOneDay = 86400000;
    const dayjsMinDate = dayjs(minDateStr, format).valueOf();
    const dayjsMaxDate = dayjs(maxDateStr, format).valueOf();

    if (isNaN(dayjsMinDate) || isNaN(dayjsMaxDate)) {
        return 0;
    }
    const diffInMS = dayjsMaxDate - dayjsMinDate;
    return Math.round(diffInMS / msInOneDay + 1);
};

export const getMonthOfDate = (date) => {
    return dayjs(date, "MM/DD/YYYY").format("MMM");
};

export const nextDayDate = () => {
    let now = dayjs();
    let d1 = now.add("1", "day");
    return d1.format("YYYY-MM-DD");
};
export const getFormattedDate = (date) => {
    if (date) {
        return date.format("DD MMMM YYYY");
    }
};

export const millisToMinutesSecondsAndMilliSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(3);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};
