import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logger from "../common/utilities/logger/index.js";
import ROUTE_CONSTANTS from "common/constants/route";
import { showLoader } from "redux/actions/index.js";

const HealthReportApp = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { sdkConfig, cmsData } = useSelector((state) => state.userDetails);

    const { ff_loader, ff_loader_top_text, ff_loader_bottom_text } = cmsData;

    useEffect(() => {
        setShowLoader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sdkConfig.emailId && sdkConfig.reportId) {
            history.push(ROUTE_CONSTANTS.HEALTH_REPORT_PURCHASE);
            const sdkValue = { ...sdkConfig };
            delete sdkValue.bearerToken;
            logger.info("Initialization health reports payment page", sdkValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sdkConfig]);

    const setShowLoader = () => {
        dispatch(
            showLoader({
                isLoading: true,
                loaderMetadata: {
                    loaderAnimation: ff_loader,
                    topContent: ff_loader_top_text,
                    bottomContent: ff_loader_bottom_text,
                },
            })
        );
    };

    return <></>;
};

export default HealthReportApp;

HealthReportApp.propTypes = {};
