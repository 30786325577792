import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { datadogLogs } from "@datadog/browser-logs";
import logger from "common/utilities/logger";
import configureStore from "./redux/store/configureStore";
import ErrorBoundary from "components/common/ErrorBoundary/ErrorBoundary";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import defaultThemeVariables from "./styles/themes/_default.module.scss";
import Transform from "./common/utilities/transform/transform";
import THEME_CONSTANTS from "common/constants/theme";
import "./styles/main.scss";
import { getEnvVar } from "common/utilities/ generic";
import appConstant from "common/constants/appConstant";

const themeVariableMapper = {
    DEFAULT: defaultThemeVariables,
};

const store = configureStore();

const { session } = store.getState();

const transformedVariables = Transform.serialize(
    themeVariableMapper[session.currentTheme || themeVariableMapper.DEFAULT]
);

const currentTheme = THEME_CONSTANTS[session.currentTheme] || THEME_CONSTANTS.DEFAULT;

let theme = createTheme(currentTheme(transformedVariables));

theme = responsiveFontSizes(theme);

// Datadog initialization
datadogLogs.init({
    clientToken: getEnvVar("REACT_APP_DD_TOKEN"),
    service: appConstant.LOGGER.LOGGER_NAME,
    site: getEnvVar("REACT_APP_DD_URL"),
    silentMultipleInit: true,
});

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Router>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);

function sendToDatadog(metric) {
    const body = JSON.stringify(metric);
    logger.info("Health Report Purchase Sdk Web Reports:", body);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToDatadog);
