import { HTTP_METHODS } from "../../../common/constants/http";
import { DEFAULT_FETCH_CONFIG } from "../../../common/constants/defaultFetchConfig";
import logger from "../../../common/utilities/logger";
import { isTypeOf } from "../../../common/utilities/types";
import headerNames from "../../../common/constants/headerNames";
import { getCurrentTimeInMs, millisToMinutesSecondsAndMilliSeconds } from "../../../common/utilities/date";
import { getEnvVar, getNestedValue, scrubEmail } from "../../../common/utilities/ generic";
import { getCorrelationId } from "../../../common/utilities/applicationIDs";
const defaultConfig = {};
const { CORRELATION_ID, MAX_AGE } = headerNames;

const getRequestHeaders = (requestConfig = {}) => {
    //construct default headers
    const defaultHeaders = {
        ...DEFAULT_FETCH_CONFIG.headers,
        [CORRELATION_ID]: getCorrelationId(),
    };

    //override the default header values
    const additionalHeaders = requestConfig.headers;
    let headers = additionalHeaders ? { ...defaultHeaders, ...additionalHeaders } : defaultHeaders;

    return headers;
};

const getRequestUrl = (path, params) => {
    let url = path;
    if (params) {
        Object.keys(params).forEach((key) => {
            /* istanbul ignore else */
            if (params[key] !== "") {
                if (url && url.indexOf("?") < 0) {
                    url += "?";
                }
                url += key + "=" + encodeURIComponent(params[key]) + "&";
            }
        });
    }
    if (url && url.lastIndexOf("&") === url.length - 1) {
        url = url.substr(0, url.length - 1);
    }
    if (typeof window === "undefined") {
        url = `${getEnvVar("REACT_APP_PLATFORM_URL")}/${url}`;
    }

    return url;
};

const createRequestBody = (requestPayload) => {
    if (isTypeOf(requestPayload, "object")) {
        try {
            return JSON.stringify(requestPayload);
        } catch (e) {
            logger.warn("Error creating the request: ", e);
            return null;
        }
    } else {
        // if requestPayload type is not object then submit as form data
        return requestPayload;
    }
};

const sendLog = (text, isFailure = false) => {
    const scrubbedText = scrubEmail(text);
    if (isFailure) {
        logger.error(scrubbedText);
    } else {
        logger.info(scrubbedText);
    }
};

const __defaultResponseErrorProcessor = (payload) => {
    if (payload.errors && payload.errors.length > 0) {
        return {
            code: payload.errors[0].errorCode,
            message: payload.errors[0].message,
        };
    }

    return null;
};

const handleSuccessResponse = async (response, getResponseError, config, url = "", responseTimer = "") => {
    //check if response object present
    if (response) {
        const body = await response.text().catch((e) => logger.error("Error parsing response: ", e.message));

        //try to parse the response
        let responsePayload;
        try {
            responsePayload = JSON.parse(body);
        } catch (e) {
            responsePayload = body;
        }

        const correlationId = responsePayload && responsePayload.correlationId;

        //look for errors in response payload
        let payloadError = null;
        if (getResponseError) {
            payloadError = getResponseError(responsePayload, config);
        }

        //construct response with headers
        const serviceResponse = {
            headers: response.headers,
            payload: responsePayload,
            responseCode: response.status,
        };

        //Handle if there is a payload error
        if (payloadError) {
            return handleFailureResponse(
                payloadError.code,
                payloadError.message,
                correlationId,
                serviceResponse,
                config,
                url,
                responseTimer
            );
        }

        // resolve promise only if response ends with ok (status code in the range 200 - 299)
        // reject promise if status code >299 or if found any payload errors
        if (response.ok) {
            sendLog(`API Success: URL: ${url}, CID: ${correlationId}, RT: ${responseTimer}`);

            return Promise.resolve(serviceResponse);
        } else {
            return handleFailureResponse(
                response.status,
                response.statusText,
                correlationId,
                serviceResponse,
                config,
                url,
                responseTimer
            );
        }
    }

    //if none of the above fallback to the default messages
    return handleFailureResponse(null, null, null, null, config, url);
};

const handleFailureResponse = (
    eCode,
    eMessage,
    eCorrelationId = null,
    serviceResponse = {},
    config,
    url = "",
    responseTimer = ""
) => {
    const errResponse = {
        error: {
            code: eCode || "API_FAIL",
            message: eMessage || getNestedValue("messages.API_FAIL", config),
        },
        ...serviceResponse,
    };

    sendLog(
        `API-FAIL, EC: ${errResponse.error.code}, CID: ${eCorrelationId}, EMSG: ${errResponse.error.message}, URL: ${url}, RT: ${responseTimer}`,
        true
    );

    return Promise.reject(errResponse);
};

class Api {
    constructor(props) {
        this.serviceProps = props;
    }

    async invoke(data, config) {
        //update the module configuration to local copy only if passed
        let __config = defaultConfig;

        if (config) {
            __config = config;
        }

        //read the custom header configuration
        let configs = {};
        if (isTypeOf(this.serviceProps.getRequestConfig, "function")) {
            configs = await this.serviceProps.getRequestConfig(data, __config);
        }

        //prepare request headers
        const headers = getRequestHeaders(configs, __config);

        //construct service endpoint
        let serviceUrl = "";
        if (this.serviceProps.method === HTTP_METHODS.GET) {
            serviceUrl = getRequestUrl(
                this.serviceProps.getPath(data),
                this.serviceProps.getRequestPayload(data, __config)
            );
        } else {
            serviceUrl = getRequestUrl(this.serviceProps.getPath(data));
        }

        //construct request payload
        const payload =
            this.serviceProps.method !== HTTP_METHODS.GET && this.serviceProps.method !== HTTP_METHODS.DELETE
                ? this.serviceProps.getRequestPayload(data, __config)
                : null;

        //construct request param
        const options = {
            headers,
            method: this.serviceProps.method,
            body: this.serviceProps.method === HTTP_METHODS.GET ? undefined : createRequestBody(payload),
            cache: configs.cache || DEFAULT_FETCH_CONFIG.cache,
            async: configs.async || DEFAULT_FETCH_CONFIG.async,
            timeout: configs.timeout || DEFAULT_FETCH_CONFIG.timeout,
            [MAX_AGE]: configs.max_age || DEFAULT_FETCH_CONFIG.max_age,
        };

        //create new request instance
        const request = new Request(serviceUrl, options);

        const requestStartTimer = getCurrentTimeInMs();

        //fetch request with callbacks
        const response = await fetch(request).catch((e) => {
            const responseTimer = millisToMinutesSecondsAndMilliSeconds(getCurrentTimeInMs() - requestStartTimer);
            return handleFailureResponse(
                "FETCH_FAILURE",
                e.message,
                headers["x-correlationid"],
                null,
                __config,
                serviceUrl,
                responseTimer
            );
        });

        const responseErrorProcessor = this.serviceProps.getResponseError || __defaultResponseErrorProcessor;

        const responseTimer = millisToMinutesSecondsAndMilliSeconds(getCurrentTimeInMs() - requestStartTimer);
        //process the response
        return handleSuccessResponse(response, responseErrorProcessor, __config, serviceUrl, responseTimer);
    }
}

export default {
    Api,
    methods: HTTP_METHODS,
};
