import { sessionActionType } from "../../actions";
import initialState from "../initialState";

export default function sessionReducer(state = initialState.session, action) {
    switch (action.type) {
        /**
         * Sets the current theme for the user session
         */
        case sessionActionType.CURRENT_THEME:
            return { ...state, currentTheme: action.theme };
        default:
            return state;
    }
}
