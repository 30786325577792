const customTags = {
    event: "event",
    eventCategory: "eventCategory",
    eventAction: "eventAction",
    eventLabel: "eventLabel",
    petType: "pet_type",
    breedNames: "breed_names",
    petAge: "pet_age",
    planType: "plan_type",
    annualPayout: "annual_payout",
    annualDeductible: "annual_deductible",
    reimbursementAmount: "reimbursement_amount",
    coverageBeginsDate: "coverage_begins_date",
    preferredBillingCycle: "preferred_billing_cycle",
    donationAmount: "donation_amount",
    donationSubmitted: "donation_submitted",
    promoCode: "promo_code",
    cartLineItemId: "cart_line_item_id",
    reportId: "report_id",
    promotion_id: "promotion_id",
    promotion_name: "promotion_name",
    postal_code: "postal_code",
};

export default customTags;
