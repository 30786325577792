const eventCategories = {
    PURCHASE_WIDGET_SDK_TOS: "Terms of Service",
    LINK_TRACKING: "Link tracking",
    PURCHASE_WIDGET_SDK_FORM_SUBMIT: "Paid Health Report Payment Confirmation",
    CARD_FIELD_ERROR: "Payment Fields",
    ADD_PROMO_CODE: "Add Promocode",
    PURCHASE_WIDGET_SDK_ONE_TIME_PROMO_CODE_SUBMIT: "Paid Health Report - OneTimePromo",
};

export default eventCategories;
