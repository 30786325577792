import React from "react";
import { Route, useParams } from "react-router-dom";
import PropTypes from "prop-types";

const PublicRoute = (props) => {
    const { path, routes } = props;
    const render = (
        subProps // eslint-disable-line react/no-multi-comp
    ) => <props.component {...subProps} routes={routes} />;

    return <Route path={path} render={render} params={useParams()} />;
};

PublicRoute.defaultProps = {
    routes: {},
};

PublicRoute.propTypes = {
    routes: PropTypes.shape({}),
    path: PropTypes.string.isRequired,
};

export default PublicRoute;
