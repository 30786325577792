import pageLoadTags from "./config/tags/pageLoadTags";
import customTags from "./config/tags/customTags";
import substituteTagRefsWithTags from "./utils/substituteTagRefsWithTags";
import sendAnalytics from "./utils/sendAnalytics";
import { getPath, getQueryString, getParamFromURL } from "../ generic";

export const sendAnalyticsOnLoad = (data = null, userId = null, emailAddress = null) => {
    if (!data) return;

    const queryString = getQueryString();
    const clickId = getParamFromURL("clickId", queryString);
    data.clickId = clickId ? decodeURIComponent(clickId) : null;
    const tagManagerDataLayer = substituteTagRefsWithTags(pageLoadTags, { ...data, userId, queryString, emailAddress });

    sendAnalytics(tagManagerDataLayer, true);
};

export const sendAnalyticsOnEvent = (data, autoAddEventLabel = true) => {
    if (!data) return;

    const eventLabel = autoAddEventLabel ? getPath() : data["eventLabel"];
    const tagManagerDataLayer = substituteTagRefsWithTags(customTags, { ...data, eventLabel });

    sendAnalytics(tagManagerDataLayer);
};
