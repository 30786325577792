import TagManager from "react-gtm-module";
import { getEnvVar } from "../../ generic";
import logger from "../../logger";

const initializeTagManager = (data) => {
    const tagManagerArgs = {
        gtmId: getEnvVar("REACT_APP_GTM_ID"),
        dataLayer: data,
    };

    sendGTMLogging(data);
    TagManager.initialize(tagManagerArgs);
};

const isGTMInitialized = () => (window?.dataLayer ? true : false);

const sendAnalytics = (data, isLoad) => {
    if (!isGTMInitialized() || isLoad) {
        initializeTagManager(data);
    } else {
        sendGTMLogging(data);

        TagManager.dataLayer({
            dataLayer: data,
        });
    }
};

const sendGTMLogging = (data) => {
    logger.info("sendAnalytics", data);
};

export default sendAnalytics;
