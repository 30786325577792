import userDetailsActionType from "./type";

export const setCmsData = (data) => {
    return {
        type: userDetailsActionType.CMS_DATA,
        data,
    };
};

export const setSdkConfig = (data) => {
    return {
        type: userDetailsActionType.SDK_CONFIG,
        data,
    };
};

export const setCustomerExists = (data) => {
    return {
        type: userDetailsActionType.CUSTOMER_EXISTS,
        data,
    };
};

export const setCustomerDetails = (data) => {
    return {
        type: userDetailsActionType.CUSTOMER_DATA,
        data,
    };
};

export const setConfirmIntentData = (data) => {
    return {
        type: userDetailsActionType.CONFIRM_INTENT_DATA,
        data,
    };
};

export const setQuoteData = (data) => {
    return {
        type: userDetailsActionType.PAID_HEALTH_QUOTE,
        data,
    };
};
