export const isTypeOf = (value, type) => typeof value === type;

export const isObject = (value) => {
    return isTypeOf(value, "object") && value !== null && !Array.isArray(value);
};

export const NON_OBJECT_VALUES = [
    null,
    [],
    1,
    NaN,
    +Infinity,
    -Infinity,
    Infinity,
    true,
    false,
    undefined,
    /* istanbul ignore next */
    () => {},
    /* istanbul ignore next */
    function a() {
        return 1;
    },
];
