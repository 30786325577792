import pageTypes from "../values/pageTypes";
import pageTitles from "../values/pageTitles";

const commonProps = {
    clickId: null,
};

const pageLoadConfig = {
    purchaseWidget: {
        ...commonProps,
        pageType: pageTypes.PURCHASE_WIDGET_SDK_PAGE,
        pageTitle: pageTitles.PURCHASE_WIDGET_SDK,
    },
    errorPage: {
        ...commonProps,
        pageType: pageTypes.PURCHASE_WIDGET_SDK_PAGE,
        pageTitle: pageTitles.PURCHASE_WIDGET_SDK_ERROR,
    },
};

export default pageLoadConfig;
