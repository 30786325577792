import commonActionType from "./type";

export const showLoader = (data) => {
    return {
        type: commonActionType.SHOW_LOADER,
        data,
    };
};

export const hideLoader = (data) => {
    return {
        type: commonActionType.HIDE_LOADER,
        data,
    };
};
