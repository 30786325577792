import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";
import { useSelector } from "react-redux";

const ButtonLoading = () => {
    const { cmsData } = useSelector((state) => state.userDetails);

    const animationContainer = useRef();

    const [animationData, setAnimationData] = useState();

    const getLoaderAnimationData = async () => {
        const { button_loader } = cmsData;

        const response = await fetch(button_loader.url);

        const animationResponse = await response.json();

        setAnimationData(animationResponse);
    };

    useEffect(() => {
        getLoaderAnimationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const animationOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
            animationData,
            container: animationContainer.current,
        };

        if (animationData) {
            lottie.loadAnimation(animationOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationData]);

    return (
        <div className="button-loading animation">
            <div className="lottie-container">
                <div className="lottie-img">
                    <div className="loader-lottie" ref={animationContainer} />
                </div>
            </div>
        </div>
    );
};

export default ButtonLoading;

ButtonLoading.propTypes = {
    payment: PropTypes.bool,
};
