import React from "react";
import { Elements } from "prismic-reactjs";

export const prepareStripePayload = (sdkConfig, formData, cardNumberElement, cardExpiryElement, cardCvcElement) => {
    const { cardName, billingZipCode } = formData;
    const { emailId, state, addressLine1, addressLine2, country, city } = sdkConfig;

    const cardHolderName = cardName.trim();

    const cardSetupPayload = {
        payment_method: {
            card: { ...cardNumberElement, ...cardExpiryElement, ...cardCvcElement },
            billing_details: {
                name: cardHolderName,
                address: {
                    city: city,
                    country: country,
                    line1: addressLine1,
                    line2: addressLine2,
                    state: state,
                    postal_code: billingZipCode.trim().toUpperCase(),
                },
                email: emailId,
            },
        },
    };

    return cardSetupPayload;
};

export const prepareConfirmIntentPayload = (setupIntent, prospectId, country, nameOnCard) => {
    const confirmIntentPayload = {
        prospectId,
        confirmSetupResponse: setupIntent,
        intentId: setupIntent.id,
        country,
        nameOnCard,
    };

    return confirmIntentPayload;
};

export const htmlSerializer = function (type, element, children, key) {
    var props = {};
    const propsWithUniqueKey = function (props, key) {
        return Object.assign(props || {}, { key });
    };
    switch (type) {
        case Elements.hyperlink:
            props = Object.assign(
                {
                    className: "link-class",
                    href: element.data.url,
                },
                element.data.target ? { target: element.data.target } : { target: "_blank" }
            );
            return React.createElement("a", propsWithUniqueKey(props, key), children);
        default:
            return null;
    }
};
