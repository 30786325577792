import eventActions from "../values/eventActions";
import customEventAnalyticsMapping from "./customEventMappings";

export const getErrorInputFieldMapping = (action, value, userDetails) => {
    let mapping = customEventAnalyticsMapping.InputFieldError;
    mapping.eventAction = `PaymentField_${action}`;
    mapping.eventLabel = value ? eventActions.FIELD_SUCCESS : eventActions.FIELD_ERROR;
    mapping.petType = "DOG";
    mapping.breedNames = userDetails.breedName;
    mapping.petAge = userDetails.petAge;
    mapping.promoCode = userDetails.promoCode;
    mapping.reportId = userDetails.reportId;

    return mapping;
};

export const getPromoCodeFieldMapping = (value, userDetails) => {
    let mapping = customEventAnalyticsMapping.PromoCodeFiled;
    mapping.eventAction = value ? eventActions.APPLY_PROMO_CODE : eventActions.REMOVE_PROMO_CODE;
    mapping.petType = "DOG";
    mapping.breedNames = userDetails.breedName;
    mapping.petAge = userDetails.petAge;
    mapping.promoCode = userDetails.promoCode;
    mapping.reportId = userDetails.reportId;

    return mapping;
};

export const getTosClickedMapping = (action, userDetails) => {
    let mapping = customEventAnalyticsMapping.healthReportFormClicked;

    mapping.eventAction = action ? eventActions.TOS_MODAL_OPEN : eventActions.TOS_MODAL_CLOSE;
    mapping.petType = "DOG";
    mapping.breedNames = userDetails.breedName;
    mapping.petAge = userDetails.petAge;
    mapping.promoCode = userDetails.promoCode;
    mapping.reportId = userDetails.reportId;

    return mapping;
};

export const getInternalLinkMapping = (destinationUrl) => {
    const mapping = customEventAnalyticsMapping.internalLinkClick;

    mapping.eventLabel = destinationUrl;

    return mapping;
};

export const getFieldMappingForPayment = (data, userDetails) => {
    const mapping = customEventAnalyticsMapping.paymentSubmit;

    mapping.eventLabel = data;
    mapping.petType = "DOG";
    mapping.breedNames = userDetails.breedName;
    mapping.petAge = userDetails.petAge;
    mapping.promoCode = userDetails.promoCode;
    mapping.reportId = userDetails.reportId;

    return mapping;
};

export const getHealthForecastSubmitPayment = (paidHealthQuote) => {
    const mapping = customEventAnalyticsMapping.forecastSubmit;

    mapping.promotion_id = paidHealthQuote?.discountType || undefined;
    mapping.promotion_name = paidHealthQuote?.promoCode || undefined;
    mapping.postal_code = paidHealthQuote?.zipCode;

    return mapping;
};

export const getFieldMappingForOneTimePromoCodePayment = (data, userDetails) => {
    const mapping = customEventAnalyticsMapping.OneTimePromoCodePaymentSubmit;

    mapping.eventLabel = data;
    mapping.petType = "DOG";
    mapping.breedNames = userDetails.breedName;
    mapping.petAge = userDetails.petAge;
    mapping.promoCode = userDetails.promoCode;
    mapping.reportId = userDetails.reportId;

    return mapping;
};
