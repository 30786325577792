import React from "react";
import PropTypes from "prop-types";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, FormHelperText, FormControl, FormGroup } from "@material-ui/core";

const CheckBox = ({
    name,
    onChange,
    onBlur,
    value,
    inputLabel,
    isDisabled,
    identifier,
    className,
    error,
    isEditable,
    autoFocus,
    autoComplete,
    color,
    ariaLabel,
    role,
}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
        },
    }));

    const BpIcon = styled("span")(({ theme }) => ({
        borderRadius: 3,
        width: 16,
        height: 16,
        backgroundColor: "#ffffff",
        border: "1px solid #515151",
        backgroundImage:
            theme.palette.mode === "dark"
                ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
                : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    }));

    const BpCheckedIcon = styled(BpIcon)({
        borderRadius: 3,
        width: 16,
        height: 16,
        backgroundColor: "#fffff",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 10,
            height: 12,
            marginTop: 1.64,
            marginLeft: 3,
            backgroundImage:
                "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMDA2MjU5MDcgNy4zNTIyOEMwLjIzNTYzOSA5LjA3NzY1IDAuODAwNTk1IDEwLjcyMTcgMS43NzEyMSAxMi4xNzA5QzIuMTc5IDEyLjc3ODkgMy4wMTc5NCAxMi44MDI0IDMuNDIxNDggMTIuMTcwOUM1LjU3MDg2IDguODA3OTMgNy43MjAyMyA1LjQ0MjgxIDkuODY5NjEgMi4wNzc2OUMxMC4xNDE1IDEuNjUzODQgOS45Njk0NCAwLjk5NDUxNSA5LjUyNzY3IDAuNzU5MDQyQzkuMDU0MDQgMC41MDY0NDQgOC41MTAzMiAwLjY1MjAwOSA4LjIxOTM1IDEuMTAzNjlDNi4wNjk5NyA0LjQ3MDk1IDMuOTIwNTkgNy44MzYwNyAxLjc3MTIxIDExLjE5OUMyLjMyMTMgMTEuMTk5IDIuODcxMzkgMTEuMTk5IDMuNDIxNDggMTEuMTk5QzMuMjEzMzQgMTAuODg4NyAzLjAyMjE5IDEwLjU2OTcgMi44NDgwMyAxMC4yMzc5QzIuNzYwOTUgMTAuMDczMSAyLjY4MDI0IDkuOTAzOTUgMi42MDE2NiA5LjczNDg0QzIuNTk5NTMgOS43MzA1NSAyLjQ4MjcyIDkuNDU2NTUgMi41NTA2OCA5LjYyMTM4QzIuNTE0NTggOS41MzU3NiAyLjQ4MDU5IDkuNDQ3OTkgMi40NDg3MyA5LjM2MDIyQzIuMzE3MDUgOS4wMTEyOSAyLjIwNDQ5IDguNjUzOCAyLjExMTA0IDguMjg5ODlDMi4wNjg1NiA4LjEyMjkyIDIuMDMwMzMgNy45NTU5NSAxLjk5NjM1IDcuNzg4OThDMS45NzcyMyA3LjY5NjkzIDEuOTYwMjQgNy42MDQ4OCAxLjk0NTM3IDcuNTEyODNDMS45MzkgNy40NzY0NCAxLjg5MjI3IDcuMTU5NjIgMS45MTc3NiA3LjM1MjI4QzEuODUxOTIgNi44NTU2NSAxLjUyNDg0IDYuMzYzMjkgMC45NjIwMSA2LjM4ODk4QzAuNTAxMTI2IDYuNDEwMzkgLTAuMDY1OTUzMyA2LjgxOTI2IDAuMDA2MjU5MDcgNy4zNTIyOFoiIGZpbGw9IiM1NzU3RjIiLz4KPC9zdmc+Cg==)",
            content: '""',
            backgroundRepeat: "no-repeat",
        },
        "input:hover ~ &": {
            backgroundColor: "#fffff",
        },
    });

    const classes = useStyles();

    const CheckBoxControl = (
        <Checkbox
            inputProps={{ "aria-label": "indeterminate checkbox" }}
            color={color}
            type="checkbox"
            disableRipple
            name={name}
            defaultValue={value}
            disabled={isDisabled}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            className={`filled-in ${identifier}`}
            id={identifier}
            onChange={(event) => {
                onChange(event, { name, value: event.target.checked });
                return event.target.checked;
            }}
            onBlur={onBlur}
            aria-label={ariaLabel}
            role={role}
        />
    );

    const EditableView = (
        <FormControl error={!!error} component="fieldset" className={classes.formControl}>
            <FormGroup>
                <FormControlLabel control={CheckBoxControl} label={inputLabel} name={name} className={className} />
            </FormGroup>
            {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
    const ReadonlyView = <span className="pre-wrap">{value ? "Yes" : "No"}</span>;

    return isEditable ? EditableView : ReadonlyView;
};

CheckBox.defaultProps = {
    isDisabled: false,
    className: "",
    color: "primary",
    inputLabel: "",
    value: false,
    error: "",
    isEditable: true,
    onChange: () => {},
    onBlur: () => {},
    ariaLabel: null,
    role: null,
};

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.bool,
    isDisabled: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    inputLabel: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isEditable: PropTypes.bool,
    ariaLabel: PropTypes.string,
    role: PropTypes.string,
};

export default CheckBox;
