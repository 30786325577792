import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader } from "redux/actions";
import { Button } from "@material-ui/core";
import logger from "common/utilities/logger";
import { sendPostMessage } from "common/utilities/eventUtil";
import { sendAnalyticsOnEvent } from "common/utilities/analytics";
import {
    getFieldMappingForOneTimePromoCodePayment,
    getHealthForecastSubmitPayment,
    getInternalLinkMapping,
} from "common/utilities/analytics/config/mappings/eventDataLayerMappings";
import TermOfService from "../termOfService";
import ButtonLoading from "components/common/LoadingIndicator/buttonLoading";
import createBuy from "services/rest/createBuy";

const AcceptAndContinue = () => {
    const dispatch = useDispatch();

    const { cmsData, sdkConfig, existingCustomer, paidHealthQuote } = useSelector((state) => state.userDetails);

    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const {
        button_arrow,
        ff_paid_new_logo,
        one_last_step,
        accept_and_continue_content,
        accept_and_continue_content_1,
        accept_and_continue_content_2,
        accept_and_continue_btn,
        privacy_policy1,
        privacy_policy_url,
        term_of_sales1,
        loading_button_name,
    } = cmsData;

    const setHideLoader = () => {
        dispatch(hideLoader({ isLoading: false }));
    };

    const setShowLoader = () => {
        setIsLoading(true);
    };

    const submitPayment = async () => {
        try {
            setShowLoader();

            logger.info("Initiating charge API for bypass application of One time promo code");

            const _preparePayload = {
                amount: 0,
                currency: paidHealthQuote?.quote?.currency,
                reportId: paidHealthQuote.reportId,
                externalReferenceId: existingCustomer.customerExternalRef,
            };

            const authToken = `Bearer ${sdkConfig?.bearerToken}`;

            await createBuy.invoke(authToken, _preparePayload);

            sendAnalyticsOnSubmitPayment("Success");

            logger.info(
                "send Post Message for bypass application of One time promo code with customerID:",
                existingCustomer.customerExternalRef
            );

            setTimeout(() => {
                sendPostMessage("healthReportPurchase-sdk-success", existingCustomer.customerExternalRef);
            }, 100);
        } catch (error) {
            sendAnalyticsOnSubmitPayment("Failure");

            if (error?.error?.code === 401 || error?.error?.code === 403) {
                logger.error("session expired in charge API for bypass application of One time promo code", error);

                setTimeout(() => {
                    sendPostMessage("healthReportPurchase-sdk-session-expired");
                }, 100);
            } else {
                logger.error("error in charge API for bypass application of One time promo code", error);

                setTimeout(() => {
                    sendPostMessage("healthReportPurchase-sdk-cancelled");
                }, 100);
            }
        }
    };

    const sendAnalyticsOnSubmitPayment = (value) => {
        const mapping = getFieldMappingForOneTimePromoCodePayment(value, {
            ...sdkConfig,
            ...formData,
        });

        sendAnalyticsOnEvent(mapping, false);


        const forecastMapping = getHealthForecastSubmitPayment(paidHealthQuote);

        sendAnalyticsOnEvent(forecastMapping, true);
    };

    const onClick = (prismicUrl) => {
        window.open(prismicUrl, "_blank");

        const mapping = getInternalLinkMapping(prismicUrl);

        sendAnalyticsOnEvent(mapping, true);
    };

    useEffect(() => {
        setHideLoader();

        let updateFormData = { ...formData };

        updateFormData = {
            ...formData,
            ...sdkConfig,
            promoCode: paidHealthQuote?.promoCode,
        };

        setFormData(updateFormData);

        logger.info("Rendered Payment method component for bypass application of One time promo code");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="ff-paid__payment-card-details__grid-container__acceptAndContinue">
                <div className="ff-paid__payment-card-details__grid-container__acceptAndContinue__heading">
                    {one_last_step}
                </div>
                <div className="ff-paid__payment-card-details__grid-container__acceptAndContinue__sub-heading">
                    <span>{accept_and_continue_content}</span>{" "}
                    <TermOfService formData={formData} name={term_of_sales1} />{" "}
                    <span>{accept_and_continue_content_1}</span>{" "}
                    <span>
                        <a href="#" onClick={() => onClick(privacy_policy_url)}>
                            {privacy_policy1}
                        </a>
                    </span>{" "}
                    <span>{accept_and_continue_content_2}</span>
                </div>
                <div className="ff-paid__payment-card-details__grid-container__acceptAndContinue__logo">
                    <img src={ff_paid_new_logo.url} alt={ff_paid_new_logo.alt} />
                </div>
                <div className="ff-paid__payment-card-details__grid-container__footer">
                    <div className="ff-paid__payment-card-details__grid-container__footer__button">
                        <Button
                            type="button"
                            size="large"
                            onClick={submitPayment}
                            fullWidth
                            variant="contained"
                            className={isLoading ? "no-click submit-button-loading" : "submit-button"}
                            id="submit"
                        >
                            {isLoading ? (
                                <span className="button-loading-content">
                                    {loading_button_name}
                                    <ButtonLoading />
                                </span>
                            ) : (
                                <>
                                    {accept_and_continue_btn}{" "}
                                    <img src={button_arrow.url} alt={button_arrow.alt} className="arrow-icon" />
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcceptAndContinue;

AcceptAndContinue.propTypes = {};
