import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logger from "common/utilities/logger";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { sendPostMessage } from "common/utilities/eventUtil";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        logger.error(error);

        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logger.error(error, errorInfo);

        setTimeout(() => {
            sendPostMessage("healthReportPurchase-sdk-cancelled");
        }, 100);
    }

    render() {
        if (this.state.hasError) {
            return <></>;
        }

        return this.props.children;
    }
}

function mapStateToProps(state) {
    return {
        session: state.session,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch),
    };
}

ErrorBoundary.propTypes = {
    children: PropTypes.shape({}),
    session: PropTypes.shape({
        currentTheme: PropTypes.string,
    }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary));
