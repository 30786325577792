import { combineReducers } from "redux";
import commonReducer from "./common/common";
import userDetailsReducer from "./userDetails/userDetails";
import sessionReducer from "./session/session";

const rootReducer = combineReducers({
    userDetails: userDetailsReducer,
    common: commonReducer,
    session: sessionReducer,
});

export default rootReducer;
