import appConstant from "common/constants/appConstant";
import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";

const preparePath = () => restEndpoints.GET_CUSTOMER_V3;

const _preparePayload = (data) => {
    const payloadData = {
        email: data.emailId,
        type: "INDIVIDUAL",
        country: data.country,
        zipcode: data.zipcode && data.zipcode.toUpperCase(),
        applicationIdentifer: appConstant.APP_ID,
        profiles: [
            {
                type: "PRIMARY",
                name: `${data.firstName}, ${data.lastName}`,
                firstName: data.firstName,
                lastName: data.lastName,
                middleName: "",
                contactDetails: [
                    {
                        type: "MOBILE",
                        value: data.phoneNumber,
                        preferenceType: "PRIMARY",
                        isPreferred: true,
                    },
                ],
                addresses: [
                    {
                        type: "MAILING",
                        line1: data.addressLine1,
                        line2: data.addressLine2,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zipcode: data.zipcode && data.zipcode.toUpperCase(),
                    },
                    {
                        type: "LOOKUP_ADDRESS",
                        line1: data.addressLine1,
                        line2: data.addressLine2,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zipcode: data.zipcode && data.zipcode.toUpperCase(),
                    },
                    {
                        type: "BILLING",
                        line1: data.addressLine1,
                        line2: data.addressLine2,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zipcode: data.zipcode && data.zipcode.toUpperCase(),
                    },
                ],

                emails: [
                    {
                        type: "PRIMARY",
                        value: data.emailId,
                        isPreferred: true,
                    },
                ],
            },
        ],
    };

    return payloadData;
};

class CreateCustomerApi extends BaseRestApi.Api {
    constructor() {
        super({
            getPath: preparePath,
            method: BaseRestApi.methods.POST,
            getRequestPayload: (data) => _preparePayload(data),
        });
    }
}

export default new CreateCustomerApi();
