const eventActions = {
    EXTERNAL_LINK_CLICKED: "External link click",
    INTERNAL_LINK_CLICKED: "Internal link click",
    TOS_MODAL_OPEN: "Terms of Service Popup Open",
    TOS_MODAL_CLOSE: "Terms of Service Popup Close",
    APPLY_PROMO_CODE: "Apply Promocode",
    REMOVE_PROMO_CODE: "Remove Promocode",
    FIELD_ERROR: "Form Field Error",
    FIELD_SUCCESS: "Form Field Success",
    PAYMENT_SUBMIT: "Submit",
    ACCEPT_AND_CONTINUE: "Accept & continue",
};

export default eventActions;
