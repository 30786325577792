import { datadogLogs } from "@datadog/browser-logs";
import { getEnvVar } from "../ generic";
import appConstants from "../../constants/appConstant";

const createLogger = () => {
    const logger = datadogLogs.createLogger(appConstants.LOGGER.LOGGER_NAME);
    logger.addContext("env", getEnvVar("REACT_APP_ENV"));

    // Set logs to console for development environment
    if (
        getEnvVar("REACT_APP_ENV") &&
        getEnvVar("REACT_APP_ENV").toUpperCase() === appConstants.ENVIRONMENTS.DEVELOPMENT
    ) {
        logger.setHandler("console");
    }

    return logger;
};

const METHODS = Object.freeze({
    DEBUG: {
        methodName: "debug",
        logLevel: 4,
    },
    INFO: {
        methodName: "info",
        logLevel: 3,
    },
    WARN: {
        methodName: "warn",
        logLevel: 2,
    },
    ERROR: {
        methodName: "error",
        logLevel: 1,
    },
});

const logMethodWrapper = (agent, method, message, ...values) => {
    // Get logs for the configured log level only or for default log level
    if (
        method &&
        method.logLevel !== appConstants.LOGGER.DEFAULT_LOG_LEVEL &&
        getEnvVar("REACT_APP_LOG_LEVEL") &&
        method.logLevel > parseInt(getEnvVar("REACT_APP_LOG_LEVEL"))
    ) {
        return;
    }

    let _agent = agent;
    if (!_agent) {
        _agent = createLogger();
    }
    const allValues = values.length > 0 ? JSON.stringify(values) : "";

    if (_agent) _agent[method.methodName](`${appConstants.LOGGER.LOGGER_NAME}: ${message} ${allValues}`);

    return _agent;
};

class Logger {
    info = (message, ...values) => {
        this._logger = logMethodWrapper(this._logger, METHODS.INFO, message, ...values);
    };

    warn = (message, ...values) => {
        this._logger = logMethodWrapper(this._logger, METHODS.WARN, message, ...values);
    };

    debug = (message, ...values) => {
        this._logger = logMethodWrapper(this._logger, METHODS.DEBUG, message, ...values);
    };

    error = (message, ...values) => {
        this._logger = logMethodWrapper(this._logger, METHODS.ERROR, message, ...values);
    };
}

let loggerInstance = null;

const getLogger = () => {
    if (!loggerInstance) loggerInstance = new Logger();
    return loggerInstance;
};

export default getLogger();
