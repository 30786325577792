import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logger from "common/utilities/logger";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { hideLoader, setConfirmIntentData, setCustomerExists, setQuoteData } from "redux/actions";
import { CheckBox, TextInput } from "components/common/FormControls";
import { Button, InputAdornment } from "@material-ui/core";
import appConstant from "common/constants/appConstant";
import { normalizeZipCode, scrollToTop } from "common/utilities/ generic";
import ROUTE_CONSTANTS from "common/constants/route";
import { prepareConfirmIntentPayload, prepareStripePayload } from "./helper";
import { get } from "lodash";
import GetQuoteApi from "services/rest/getQuote";
import { sendPostMessage } from "common/utilities/eventUtil";
import ConfirmIntentApi from "services/rest/confirmIntentApi";
import {
    getErrorInputFieldMapping,
    getFieldMappingForPayment,
    getHealthForecastSubmitPayment,
    getInternalLinkMapping,
    getPromoCodeFieldMapping,
} from "common/utilities/analytics/config/mappings/eventDataLayerMappings";
import { sendAnalyticsOnEvent, sendAnalyticsOnLoad } from "common/utilities/analytics";
import createBuy from "services/rest/createBuy";
import ButtonLoading from "components/common/LoadingIndicator/buttonLoading";
import pageLoadConfig from "common/utilities/analytics/config/mappings/pageLoadMappings";
import TermOfService from "../termOfService";

let cardNumberElement = {};
let cardExpiryElement = {};
let cardCvcElement = {};

const PaymentCardDetails = ({
    paymentKeys,
    customerId,
    setError,
    isError,
    isApiError,
    setIsApiError,
    refreshComponent,
    formData,
    setFormData,
    isLoading,
    setIsLoading,
}) => {
    const initCardDetails = {
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
    };

    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    let history = useHistory();

    const { cmsData, confirmIntentData, sdkConfig, paidHealthQuote } = useSelector((state) => state.userDetails);
    const currency =
        sdkConfig.country === appConstant.COUNTRY_CODE_US ? appConstant.US_DOLLAR : appConstant.CANADIAN_DOLLAR;
    const [discountMatch, discountWholeDigits, discountDecimalDigits] =
        String(paidHealthQuote?.quote?.discountedPrice?.toFixed(2)).match(appConstant.PRICE_EXPRESSION) || [];
    const [totalMatch, totalWholeDigits, totalDecimalDigits] =
        String(paidHealthQuote?.quote?.price?.toFixed(2)).match(appConstant.PRICE_EXPRESSION) || [];
    const totalSaving = (Number(totalMatch) - Number(discountMatch)).toFixed(2);

    const {
        ff_paid_new_logo,
        one_time_payment,
        one_time_payment_sub_heading,
        no_monthly_subscription_sub_heading,
        card_details_heading,
        name_on_card,
        billing_zip_code,
        card_number,
        card_expiry,
        card_cvv,
        card_img,
        promo_code_label,
        promo_code_btn,
        promo_code_success,
        promo_code_close_btn,
        promo_code_error,
        expired_promo_code_error,
        redeemed_promo_code_error,
        accept_message,
        acknowledgement_error,
        term_of_sales,
        lock_icon,
        secured_message,
        stripe_icon,
        privacy_policy,
        privacy_policy_url,
        payment_error,
        payment_error_heading,
        payment_error_content,
        button_name,
        button_arrow,
        loading_button_name,
    } = cmsData;

    const paymentCard = card_img.map((option) => {
        return {
            ...option,
            value: false,
        };
    });

    const [cardType, setCartType] = useState(paymentCard);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [isCardCvvValid, setIsCardCvvValid] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [isPromoCodeButtonDisable, setIsPromoCodeButtonDisable] = useState(true);
    const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
    const [isPromoCodeError, setIsPromoCodeError] = useState(false);
    const [isPromoCodeRedeemedError, setIsPromoCodeRedeemedError] = useState(false);
    const [isPromoCodeErrorMessage, setIsPromoCodeErrorMessage] = useState("");
    const [fieldError, setFieldError] = useState({});
    const [isPromoCodeLoading, setIsPromoCodeLoading] = useState(false);

    const mountCartDetails = () => {
        const styleVar = {
            base: {
                fontFamily: `"DM Sans", sans-serif`,
                fontSize: "16px",
                padding: 0,
                fontWeight: 400,
                color: "#000000",
                ":-webkit-autofill": {
                    color: "#000000",
                },
                "::placeholder": {
                    fontWeight: 400,
                    color: "#515151",
                    fontSize: "16px",
                    fontFamily: `"DM Sans", sans-serif`,
                },
            },
        };

        cardNumberElement = elements.create("cardNumber", {
            showIcon: false,
            placeholder: card_number,
            style: styleVar,
        });
        cardNumberElement.mount("#card-number");
        cardNumberElement.on("change", (event) => {
            onCardDetailsChange(event.brand);
            setError(false);
            setIsApiError(false);
            setIsCardNumberValid(event.complete);
        });

        cardExpiryElement = elements.create("cardExpiry", {
            placeholder: card_expiry,
            style: styleVar,
        });
        cardExpiryElement.mount("#expiration");
        cardExpiryElement.on("change", (event) => {
            setError(false);
            setIsApiError(false);
            setIsCardExpiryValid(event.complete);
        });

        cardCvcElement = elements.create("cardCvc", {
            placeholder: card_cvv,
            style: styleVar,
        });
        cardCvcElement.mount("#cvc");
        cardCvcElement.on("change", (event) => {
            setError(false);
            setIsApiError(false);
            setIsCardCvvValid(event.complete);
        });
    };

    const setShowLoader = () => {
        setIsLoading(true);
    };

    const setHideLoader = () => {
        dispatch(hideLoader({ isLoading: false }));

        setIsLoading(false);
    };

    const onCardDetailsChange = (brand) => {
        const paymentCardCopy = [...paymentCard];

        paymentCardCopy.forEach((item) => {
            const updatedItem = item;

            if (item.name === brand) {
                updatedItem.value = true;
            } else {
                updatedItem.value = false;
            }
        });

        setCartType(paymentCardCopy);
    };

    const handleOnChange = (e, value) => {
        setFormData({
            ...formData,
            [value.name]: value.value,
        });

        if (value.name === "acknowledgeTermsOfService") {
            sendAnalyticsOnInputFieldError(value.name, value.value);

            const copyFieldError = { ...fieldError };
            delete copyFieldError["acknowledgeTermsOfService"];
            setFieldError(copyFieldError);
        }

        if (value.name === "promoCode" && (isPromoCodeError || isPromoCodeRedeemedError)) {
            setIsPromoCodeError(false);
            setIsPromoCodeRedeemedError(false);
            setIsPromoCodeErrorMessage("");
        }
    };

    const handleOnBlur = (e) => {
        const targetName = e.target.name;

        const copyFieldError = { ...fieldError };

        if (e.target.value.trim() === "" && targetName !== "promoCode") {
            copyFieldError[targetName] = true;

            sendAnalyticsOnInputFieldError(targetName, false);
        } else {
            delete copyFieldError[targetName];

            if (targetName === "billingZipCode" && formData.isValidZip) {
                sendAnalyticsOnInputFieldError(targetName, true);
            }

            if (targetName !== "billingZipCode" && targetName !== "promoCode") {
                sendAnalyticsOnInputFieldError(targetName, true);
            }
        }

        if (targetName === "billingZipCode" && !formData.isValidZip) {
            copyFieldError[targetName] = true;

            sendAnalyticsOnInputFieldError(targetName, false);
        }

        if (targetName === "promoCode") {
            copyFieldError[targetName] = false;
        }

        setFieldError(copyFieldError);
    };

    const handleKeyup = (e) => {
        const value = e.target.value;

        const code = normalizeZipCode(value);

        const usPattern = new RegExp(appConstant.US_ZIP_CODE_PATTERN);
        const caPattern = new RegExp(appConstant.CAN_ZIP_CODE_PATTERN);

        const isValidZip = usPattern.test(code.toString()) || caPattern.test(code.toString().replace(/\W+/g, ""));

        setFormData({ ...formData, isValidZip });
    };

    const checkFormInvalid = () => {
        const validateForm = { ...formData };
        delete validateForm.promoCode;

        return Object.values(validateForm).some((v) => v === null || v.toString().trim() === "" || v === false);
    };

    const checkCardDetailsInvalid = () => {
        if (!isCardNumberValid || !isCardExpiryValid || !isCardCvvValid) {
            return true;
        } else {
            return false;
        }
    };

    const validateNewCustomerForm = () => {
        const isFormDataInvalid = checkFormInvalid();

        const isCardDetailsInvalid = checkCardDetailsInvalid();

        return !isFormDataInvalid && !isCardDetailsInvalid;
    };

    const getHealthReportQuote = async (promoCode = "") => {
        try {
            logger.info("Initiating get Quote API");

            const quotePayload = { ...sdkConfig, customerId, promoCode };

            const authToken = `Bearer ${sdkConfig?.bearerToken}`;

            const quoteResponse = await GetQuoteApi.invoke(authToken, quotePayload);

            const data = get(quoteResponse, "payload.payload.response", {});

            dispatch(setQuoteData(data));

            return data;
        } catch (error) {
            logger.error("error in get Quote API", error);

            history.push(ROUTE_CONSTANTS.ERROR);
        }
    };

    const verifyPromoCode = async () => {
        setIsPromoCodeLoading(true);

        logger.info("Initiating verify promo code API");

        const payload = await getHealthReportQuote(formData.promoCode);

        checkPromCodeValidation(payload);
    };

    const checkPromCodeValidation = (payload) => {
        if (payload.isValidPromoCode && (payload?.isOneTimePromoCodeApplied || payload?.discountPercentage === 100)) {
            logger.info(`Valid Promo code with ${payload?.discountPercentage} discount`, payload.promoCode);

            dispatch(setCustomerExists({ customerExternalRef: customerId }));

            history.push(ROUTE_CONSTANTS.FREE_HEALTH_REPORT_PURCHASE);
        } else if (payload.isValidPromoCode && payload?.discountPercentage !== 0) {
            logger.info(`Valid Promo code ${payload?.discountPercentage} discount`, payload.promoCode);

            setIsPromoCodeValid(true);
            setIsPromoCodeError(false);

            sendAnalyticsOnInputFieldError("promoCode", true, payload?.promoCode);
            sendAnalyticsOnPromoCodeRemove(true, payload?.promoCode);
        } else if (
            payload.isValidPromoCode &&
            !payload?.isOneTimePromoCodeApplied &&
            !payload?.isPromoCodeExpired &&
            payload?.discountPercentage === 0
        ) {
            logger.info("Already redeemed Promo code", payload.promoCode);

            setIsPromoCodeValid(false);
            setIsPromoCodeRedeemedError(true);
            setIsPromoCodeErrorMessage(redeemed_promo_code_error);

            scrollToTop();

            sendAnalyticsOnInputFieldError("promoCode", false, payload?.promoCode);
        } else if (payload.isValidPromoCode && payload?.isPromoCodeExpired) {
            logger.info("expired Promo code", payload.promoCode);

            setIsPromoCodeValid(false);
            setIsPromoCodeError(true);
            setIsPromoCodeErrorMessage(expired_promo_code_error);

            sendAnalyticsOnInputFieldError("promoCode", false, payload?.promoCode);
        } else {
            logger.info("Invalid Promo code", payload.promoCode);

            setIsPromoCodeValid(false);
            setIsPromoCodeError(true);
            setIsPromoCodeErrorMessage(promo_code_error);

            sendAnalyticsOnInputFieldError("promoCode", false, payload?.promoCode);
        }

        setIsPromoCodeLoading(false);
    };

    const changePromoCode = async (event) => {
        setIsPromoCodeLoading(true);
        event.preventDefault();

        logger.info("Click on Remove Promo code");

        await getHealthReportQuote();

        sendAnalyticsOnPromoCodeRemove(false);

        setFormData({ ...formData, promoCode: "" });

        setIsPromoCodeValid(false);
        setIsPromoCodeLoading(false);
    };

    const getQuoteData = async (promoCode) => {
        const payload = await getHealthReportQuote(promoCode);

        sendAnalyticsOnLoad(pageLoadConfig.purchaseWidget, customerId, sdkConfig.emailId);

        if (promoCode) checkPromCodeValidation(payload);

        setHideLoader();

        logger.info("Rendered Paid health forecast Payment-card component successfully");
    };

    useEffect(() => {
        let updateFormData = { ...formData };
        const { firstName, lastName, zipcode, promoCode = "" } = sdkConfig;

        updateFormData = {
            ...formData,
            cardName: `${firstName} ${lastName}`.trim(),
            billingZipCode: zipcode,
            isValidZip: zipcode ? true : false,
            acknowledgeTermsOfService: false,
            promoCode,
        };

        setFormData(updateFormData);

        getQuoteData(promoCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (elements) mountCartDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements]);

    useEffect(() => {
        const getValidators = () => [validateNewCustomerForm];

        const validators = getValidators();

        const isDisabled = validators.some((validator) => validator.call());

        setIsButtonDisable(!isDisabled);

        if (formData.promoCode && formData.promoCode.trim()) {
            setIsPromoCodeButtonDisable(false);
        } else {
            setIsPromoCodeButtonDisable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isButtonDisable, formData, isCardNumberValid, isCardExpiryValid, isCardCvvValid]);

    useEffect(() => {
        if (confirmIntentData && confirmIntentData.walletId) {
            chargeAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmIntentData]);

    const submitPayment = async () => {
        setIsApiError(false);
        setIsPromoCodeRedeemedError(false);

        logger.info("Validating form, card details & preparing for stripe confirm Card Setup API");

        const isFormDataInvalid = checkFormInvalid();
        const isCardDetailsInvalid = checkCardDetailsInvalid();

        if (!isFormDataInvalid && !isCardDetailsInvalid) {
            setShowLoader();

            logger.info("Initiating Stripe confirm Card Setup API");

            const cardSetupPayload = prepareStripePayload(
                sdkConfig,
                formData,
                cardNumberElement,
                cardExpiryElement,
                cardCvcElement
            );

            const setupIntent = await confirmCardSetup(paymentKeys.secret, cardSetupPayload);

            if (setupIntent) {
                confirmIntent(setupIntent);
            } else {
                setError(true);

                setHideLoader();
            }
        } else {
            sendAnalyticsOnSubmitPayment("Failure");

            logger.info(
                `Form data OR Card details are INVALID: Form Data: ${isFormDataInvalid} && Card Details: ${isCardDetailsInvalid}}`
            );

            const copyFieldError = { ...fieldError };

            if (!formData.acknowledgeTermsOfService) {
                copyFieldError["acknowledgeTermsOfService"] = true;
            }

            setFieldError(copyFieldError);

            setHideLoader();
        }
    };

    const confirmCardSetup = async (secretKey, Payload) => {
        try {
            const { setupIntent, error } = await stripe.confirmCardSetup(secretKey, Payload);

            if (error) {
                sendAnalyticsOnSubmitPayment("Failure");

                logger.error("Error in stripe confirm card setup API", error);
            }

            return setupIntent;
        } catch (error) {
            sendAnalyticsOnSubmitPayment("Failure");

            logger.error("Error in stripe confirm card setup API", error);

            return error;
        }
    };

    const confirmIntent = async (setupIntent) => {
        try {
            logger.info("Initiating confirm intent API");

            const countryCode = sdkConfig.country;

            const authToken = `Bearer ${sdkConfig?.bearerToken}`;

            const nameOnCard = formData.cardName.trim();

            const confirmIntentPayload = prepareConfirmIntentPayload(setupIntent, customerId, countryCode, nameOnCard);

            const confirmIntentApi = new ConfirmIntentApi(authToken, confirmIntentPayload);

            const data = await confirmIntentApi.invoke();

            dispatch(setConfirmIntentData(data?.payload?.payload?.response));
        } catch (error) {
            if (error?.error?.code === 401 || error?.error?.code === 403) {
                sendAnalyticsOnSubmitPayment("Failure");

                logger.error("session expired in confirm intent API call", error);

                setTimeout(() => {
                    sendPostMessage("healthReportPurchase-sdk-session-expired");
                }, 100);
            } else {
                logger.error("error in confirm intent API", error);

                setIsApiError(true);

                handlePaymentError();
            }
        }
    };

    const chargeAPI = async () => {
        try {
            const amount = paidHealthQuote?.quote?.discountedPrice?.toFixed(2);
            logger.info("Initiating charge API", amount);

            const _preparePayload = {
                ...confirmIntentData,
                amount,
                currency: paidHealthQuote?.quote?.currency,
                reportId: paidHealthQuote.reportId,
            };

            const authToken = `Bearer ${sdkConfig?.bearerToken}`;

            const data = await createBuy.invoke(authToken, _preparePayload);

            const transaction = get(data, "payload.payload.response.transaction", "");

            if (transaction?.transactionStatus?.status === "Approved") {
                sendAnalyticsOnSubmitPayment("Success");

                logger.info("send Post Message for Paid Health Report with customerID:", customerId);

                setTimeout(() => {
                    sendPostMessage("healthReportPurchase-sdk-success", customerId);
                }, 100);
            } else {
                logger.log("error in charge API", transaction);
                setIsApiError(true);

                handlePaymentError();
            }
        } catch (error) {
            if (error?.error?.code === 401 || error?.error?.code === 403) {
                logger.error("session expired in charge API", error);

                setTimeout(() => {
                    sendPostMessage("healthReportPurchase-sdk-session-expired");
                }, 100);
            } else {
                logger.error("error in charge API", error);

                setIsApiError(true);

                handlePaymentError();
            }
        }
    };

    const handlePaymentError = () => {
        sendAnalyticsOnSubmitPayment("Failure");

        dispatch(setQuoteData({}));
        dispatch(setConfirmIntentData({}));

        refreshComponent(true);
    };

    const onClick = (prismicUrl) => {
        window.open(prismicUrl, "_blank");

        const mapping = getInternalLinkMapping(prismicUrl);

        sendAnalyticsOnEvent(mapping, true);
    };

    const sendAnalyticsOnInputFieldError = (targetName, value, promoCode = formData.promoCode) => {
        const mapping = getErrorInputFieldMapping(targetName, value, { ...sdkConfig, ...formData, promoCode });

        sendAnalyticsOnEvent(mapping, false);
    };

    const sendAnalyticsOnPromoCodeRemove = (value, promoCode = formData.promoCode) => {
        const mapping = getPromoCodeFieldMapping(value, { ...sdkConfig, ...formData, promoCode });

        sendAnalyticsOnEvent(mapping, false);
    };

    const sendAnalyticsOnSubmitPayment = (value) => {
        const mapping = getFieldMappingForPayment(value, { ...sdkConfig, ...formData });

        sendAnalyticsOnEvent(mapping, false);

        const forecastMapping = getHealthForecastSubmitPayment(paidHealthQuote);

        sendAnalyticsOnEvent(forecastMapping, true);
    };

    return (
        <>
            <div className="ff-paid__container">
                <div className="ff-paid__container__header">
                    <img src={ff_paid_new_logo.url} alt={ff_paid_new_logo.alt} />
                </div>
            </div>
            <div className={isLoading ? "ff-paid__payment-card-details no-click" : "ff-paid__payment-card-details"}>
                <div className="ff-paid__payment-card-details__grid-container">
                    <div className="ff-paid__payment-card-details__grid-container__main">
                        {isPromoCodeRedeemedError && (
                            <div className="ff-paid__payment-card-details__grid-container__main__promoCode-error">
                                <p>{isPromoCodeErrorMessage}</p>
                            </div>
                        )}
                        <div
                            className={`ff-paid__payment-card-details__grid-container__main__price-bubble ${
                                Number(discountDecimalDigits) > 0
                                    ? `decimal__price-bubble__${
                                          sdkConfig.country === appConstant.COUNTRY_CODE_US
                                              ? appConstant.COUNTRY_CODE_US
                                              : appConstant.COUNTRY_CODE_CA
                                      }`
                                    : ""
                            }`}
                        >
                            <div className="ff-paid__payment-card-details__grid-container__main__price-bubble__container">
                                <div className="just">{one_time_payment}</div>
                                {Number(totalSaving) > 0 && isPromoCodeValid && (
                                    <div className="price-discount-container">
                                        <div className="discount-amount">
                                            <span className="currency">{currency}</span>
                                            <span className="amount">{`${totalWholeDigits}`}</span>
                                            {Number(totalDecimalDigits) > 0 && (
                                                <span className="decimalAmount">{`.${totalDecimalDigits}`}</span>
                                            )}
                                        </div>
                                        <span className="line-through"></span>
                                    </div>
                                )}
                                <div className="total-amount">
                                    <span className="currency">{currency}</span>
                                    <span className="amount">{`${discountWholeDigits}`}</span>
                                    {Number(discountDecimalDigits) > 0 && (
                                        <span className="decimalAmount">{`.${discountDecimalDigits}`}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="ff-paid__payment-card-details__grid-container__main__fetch-heading">
                            {one_time_payment_sub_heading}
                        </div>
                        <div className="ff-paid__payment-card-details__grid-container__main__fetch-heading-month-sub">
                            {no_monthly_subscription_sub_heading}
                        </div>

                        <div className="ff-paid__payment-card-details__grid-container__main__heading">
                            {card_details_heading}
                        </div>

                        {isError && (
                            <div className="ff-paid__payment-card-details__grid-container__main__payment-error">
                                <p>{payment_error}</p>
                            </div>
                        )}

                        {isApiError && (
                            <div className="ff-paid__payment-card-details__grid-container__main__payment-error">
                                <p>
                                    <b>{payment_error_heading}</b>
                                    {payment_error_content}
                                </p>
                            </div>
                        )}

                        <div className="ff-paid__payment-card-details__grid-container__main__card-img">
                            {cardType.map((option, index) => {
                                return (
                                    <img
                                        src={option.card_icon_img.url}
                                        alt={option.card_icon_img.alt}
                                        className={option.value ? "" : "opacity-Card"}
                                        key={index}
                                    />
                                );
                            })}
                        </div>

                        <div className="ff-paid__payment-card-details__grid-container__main__card">
                            <TextInput
                                name="cardName"
                                value={formData.cardName}
                                onChange={handleOnChange}
                                identifier="card-holder-name"
                                type="text"
                                inputVariant="filled"
                                inputLabel={name_on_card}
                                error={fieldError["cardName"]}
                                onBlur={handleOnBlur}
                            />

                            <div className="ff-paid__payment-card-details__grid-container__main__card-details">
                                <div className="form__field-group stripe-input-element cardNumber">
                                    <div className="input-group">
                                        <div className="input-group__wrapper">
                                            <div
                                                name="CardNumber"
                                                onChange={handleOnChange}
                                                required={true}
                                                value={initCardDetails.cardNumber}
                                                id="card-number"
                                                className={"input__control"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="ff-paid__payment-card-details__grid-container__main__card-details-group">
                                    <div className="form__field-group stripe-input-element expiration">
                                        <div className="input-group">
                                            <div className="input-group__wrapper">
                                                <div
                                                    className={"input__control"}
                                                    name="Expiration"
                                                    onChange={handleOnChange}
                                                    required={true}
                                                    value={initCardDetails.cardExpiry}
                                                    id="expiration"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form__field-group stripe-input-element cvc">
                                        <div className="input-group">
                                            <div className="input-group__wrapper">
                                                <div
                                                    className={"input__control"}
                                                    name="CVC"
                                                    onChange={handleOnChange}
                                                    required={true}
                                                    value={initCardDetails.cardCvc}
                                                    id="cvc"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form__field-group stripe-zip-code zipCode">
                                    <TextInput
                                        name="billingZipCode"
                                        value={formData.billingZipCode}
                                        onChange={handleOnChange}
                                        identifier="billingZipCode"
                                        type="text"
                                        inputVariant="filled"
                                        inputLabel={billing_zip_code}
                                        error={fieldError["billingZipCode"]}
                                        onBlur={handleOnBlur}
                                        onKeyUp={handleKeyup}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="ff-paid__payment-card-details__grid-container__main__secure-message">
                            <img src={lock_icon.url} alt={lock_icon.alt} />
                            <span className="secured_message">{secured_message}</span>
                            <img src={stripe_icon.url} alt={stripe_icon.alt} />
                        </div>

                        <div className="ff-paid__payment-card-details__grid-container__main__promo-code">
                            {!isPromoCodeValid && (
                                <>
                                    <TextInput
                                        name="promoCode"
                                        value={formData.promoCode}
                                        onChange={handleOnChange}
                                        identifier="promo-code"
                                        type="text"
                                        inputVariant="filled"
                                        inputLabel={promo_code_label}
                                        error={isPromoCodeError}
                                        onBlur={handleOnBlur}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button
                                                    type="button"
                                                    size="small"
                                                    onClick={verifyPromoCode}
                                                    variant="containedSecondary"
                                                    className={
                                                        isPromoCodeLoading
                                                            ? `apply-button-${isPromoCodeError} no-click`
                                                            : `apply-button-${isPromoCodeError}`
                                                    }
                                                    id="apply"
                                                    disabled={isPromoCodeButtonDisable}
                                                >
                                                    {promo_code_btn}
                                                </Button>
                                            </InputAdornment>
                                        }
                                    />
                                    {isPromoCodeError && isPromoCodeErrorMessage && (
                                        <span className="ff-paid__payment-card-details__grid-container__main__promo-code__failed">
                                            {isPromoCodeErrorMessage}
                                        </span>
                                    )}
                                </>
                            )}
                            {isPromoCodeValid && (
                                <div className="ff-paid__payment-card-details__grid-container__main__promo-code__success">
                                    <div className="ff-paid__payment-card-details__grid-container__main__promo-code__success__left">
                                        <span>{`${promo_code_success} ${currency}${totalSaving}`}</span>
                                        <span>{`${formData.promoCode} (${paidHealthQuote.discountPercentage}%)`}</span>
                                    </div>
                                    <div className="ff-paid__payment-card-details__grid-container__main__promo-code__success__right">
                                        <a
                                            href="javascript.void(0)"
                                            className={isPromoCodeLoading ? "close-button no-click" : "close-button"}
                                            onClick={(e) => changePromoCode(e)}
                                        >
                                            <img
                                                src={promo_code_close_btn.url}
                                                alt={promo_code_close_btn.alt}
                                                className="close-icon"
                                            />
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="ff-paid__payment-card-details__grid-container__main__checkbox-accept">
                            <CheckBox
                                name="acknowledgeTermsOfService"
                                identifier="acknowledgeTermsOfService"
                                onChange={handleOnChange}
                                color="default"
                                value={formData.acknowledgeTermsOfService}
                                className={fieldError["acknowledgeTermsOfService"] ? "acknowledge-error" : ""}
                            />
                            <div>
                                <span className="accept-msg">{accept_message}</span>
                                <TermOfService formData={formData} name={term_of_sales} />
                            </div>
                        </div>

                        {fieldError["acknowledgeTermsOfService"] && (
                            <div className="ff-paid__payment-card-details__grid-container__main__checkbox-accept-error">
                                {acknowledgement_error}
                            </div>
                        )}

                        <div className="ff-paid__payment-card-details__grid-container__main__privacy-policy">
                            <span>
                                <a href="#" onClick={() => onClick(privacy_policy_url)}>
                                    {privacy_policy}
                                </a>
                            </span>
                        </div>
                    </div>

                    <div className="ff-paid__payment-card-details__grid-container__footer">
                        <div className="ff-paid__payment-card-details__grid-container__footer__button">
                            <Button
                                type="button"
                                size="large"
                                onClick={submitPayment}
                                fullWidth
                                variant="contained"
                                className={
                                    isLoading
                                        ? "no-click submit-button-loading"
                                        : isButtonDisable
                                        ? "submit-button disabled-button"
                                        : "submit-button"
                                }
                                id="submit"
                            >
                                {isLoading ? (
                                    <span className="button-loading-content">
                                        {loading_button_name}
                                        <ButtonLoading />
                                    </span>
                                ) : (
                                    <>
                                        {button_name}{" "}
                                        <img src={button_arrow.url} alt={button_arrow.alt} className="arrow-icon" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentCardDetails;

PaymentCardDetails.propTypes = {
    paymentKeys: PropTypes.object,
    isCardValeChange: PropTypes.number,
    customerId: PropTypes.string,
    setError: PropTypes.func,
    isError: PropTypes.bool,
    setIsApiError: PropTypes.func,
    isApiError: PropTypes.bool,
    refreshComponent: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
};
