import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader } from "redux/actions";
import { sendAnalyticsOnLoad } from "common/utilities/analytics";
import pageLoadConfig from "common/utilities/analytics/config/mappings/pageLoadMappings";
import { sendPostMessage } from "common/utilities/eventUtil";

const Error = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideLoader({ isLoading: false }));

        sendAnalyticsOnLoad(pageLoadConfig.errorPage);

        setTimeout(() => {
            sendPostMessage("healthReportPurchase-sdk-cancelled");
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

export default Error;
