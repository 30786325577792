import { isArray, isJson, isJsonEmpty } from "../../ generic";

const substituteTagRefsWithTags = (tags, tagRefs) => {
    return Object.keys(tagRefs).reduce((mapping, key) => {
        if (tagRefs[key]) {
            let subObject = tagRefs[key];

            if (isArray(subObject)) {
                subObject = subObject.reduce((newSub, iter) => {
                    newSub.push(substituteTagRefsWithTags(tags, iter));
                    return newSub;
                }, []);
            } else if (isJson(subObject) && !isJsonEmpty(subObject)) {
                subObject = substituteTagRefsWithTags(tags, subObject);
            }

            mapping[tags[key]] = subObject;
        } else {
            let subObject = tagRefs[key];

            if (subObject === undefined) {
                mapping[tags[key]] = undefined;
            } else {
                // If value is null or blank then attribute will go with a "[NOT SET]" value
                mapping[tags[key]] = "[NOT SET]";
            }
        }

        return mapping;
    }, {});
};

export default substituteTagRefsWithTags;
