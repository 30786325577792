import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

const ModalPanel = ({ identifier, showDialog, children, onClose }) => {
    const classes = useStyles();

    const className = `${identifier}-close-button`;

    return (
        <Dialog
            open={showDialog}
            id={identifier}
            onClose={onClose}
            maxWidth="sm"
            scroll="body"
            transitionDuration={500}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="modal-wrapper"
        >
            {children}
            <span className={className}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </span>
        </Dialog>
    );
};

ModalPanel.propTypes = {
    identifier: PropTypes.string.isRequired,
    showDialog: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ModalPanel;
