const events = {
    PURCHASE_WIDGET_SDK_TOS: "PaidHealthReport_TermsOfService",
    INTERNAL_LINK: "PaidHealthReport_InternalLink",
    PAYMENT_SUBMIT: "PaidHealthReport_PaymentConfirmation",
    FORECAST_SUBMIT: "health_forecast_submit",
    CARD_FIELD_ERROR: "PaidHealthReport_PaymentFields",
    PROMO_CODE_FIELD: "PaidHealthReport_PaymentAddPromoCode",
    ONE_TIME_PROMO_CODE_PAYMENT_SUBMIT: "PaidHealthReport_OneTimePromoConfirmation",
};

export default events;
