import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { getTosClickedMapping } from "common/utilities/analytics/config/mappings/eventDataLayerMappings";
import { sendAnalyticsOnEvent } from "common/utilities/analytics";
import { useSelector } from "react-redux";
import ModalPanel from "components/common/Panel/modal/modal";
import { DialogContent } from "@material-ui/core";
import { htmlSerializer } from "../paymentCardDetails/helper";

const TermOfService = ({ formData, name }) => {
    const { cmsData, sdkConfig } = useSelector((state) => state.userDetails);
    const { currentTheme } = useSelector((state) => state.session);

    const [isModalOpen, setModalOpen] = useState(false);

    const { tos_heading, tos_description, tos_close } = cmsData;

    const onDialogOpen = () => {
        setModalOpen(true);

        sendAnalyticsOnModalLoad(true);
    };

    const onDialogClose = () => {
        setModalOpen(false);

        sendAnalyticsOnModalLoad(false);
    };

    const sendAnalyticsOnModalLoad = (value) => {
        const mapping = getTosClickedMapping(value, { ...sdkConfig, ...formData });

        sendAnalyticsOnEvent(mapping, true);
    };

    return (
        <>
            <span className="term-of-sales">
                <a href="#" onClick={() => onDialogOpen()}>{`${name}`}</a>
            </span>
            <ModalPanel identifier={`tos-modal`} showDialog={isModalOpen} onClose={() => onDialogClose()}>
                <DialogContent>
                    <div className={`theme-${currentTheme.toLowerCase()}`}>
                        <div className="tos-modal-heading">{tos_heading}</div>
                        <div className="tos-modal-content">
                            <RichText render={tos_description} htmlSerializer={htmlSerializer} />
                        </div>
                        <div className="tos-modal-close">
                            <a href="#" onClick={() => onDialogClose()}>
                                {tos_close}
                            </a>
                        </div>
                    </div>
                </DialogContent>
            </ModalPanel>
        </>
    );
};

export default TermOfService;

TermOfService.propTypes = {
    formData: PropTypes.object,
    name: PropTypes.string,
};
