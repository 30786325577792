export const DEFAULT_FETCH_CONFIG = {
    timeout: 60000,
    headers: {
        "Content-Type": "application/json",
    },
    async: true,
    cache: "no-store",
    max_age: 0,
    skip_headers: [],
};
