import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";

const _preparePath = () => restEndpoints.GET_CUSTOMER_EXIST;

class GetCustomerExists extends BaseRestApi.Api {
    constructor() {
        super({
            getPath: _preparePath,
            method: BaseRestApi.methods.GET,
            getRequestPayload: (data) => data,
        });
    }
}

export default new GetCustomerExists();
