import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import logger from "../../common/utilities/logger/index.js";
import getCustomerExists from "../../services/rest/getCustomerExists";
import { setCustomerDetails, setCustomerExists } from "redux/actions";
import ROUTE_CONSTANTS from "common/constants/route";

import createCustomerApi from "services/rest/createCustomerApi/index.js";
import appConstant from "common/constants/appConstant.js";
import PaymentDetails from "components/health-report-purchase/paymentDetails/index.js";

const HealthReportPurchase = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { sdkConfig } = useSelector((state) => state.userDetails);
    const { country, bearerToken } = sdkConfig;

    const initialFormData = {
        billingZipCode: "",
        cardName: "",
        promoCode: "",
        acknowledgeTermsOfService: false,
        isValidZip: false,
    };

    const [customerId, setCustomerID] = useState("");
    const [isError, setError] = useState(false);
    const [isApiError, setIsApiError] = useState(false);
    const [isComponentRefresh, setIsComponentRefresh] = useState(false);
    const [paymentKeys, setPaymentKeys] = useState({});
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    let countryCode = country;
    if (countryCode === appConstant.COUNTRY_CODE_CANADA) {
        countryCode = appConstant.COUNTRY_CODE_CA;
    }

    useEffect(() => {
        checkExistingCustomer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshComponent = () => {
        setIsComponentRefresh(!isComponentRefresh);
    };

    const checkExistingCustomer = async () => {
        const customerData = await getCustomer();

        if (!customerData.isExist) {
            try {
                logger.info("Initiating create customer API");

                const createCustomerPayload = { ...sdkConfig };

                const customerPayload = await createCustomerApi.invoke(createCustomerPayload);

                dispatch(setCustomerDetails(customerPayload?.payload?.payload?.response?.customers));

                const customerExternalRef = get(
                    customerPayload,
                    "payload.payload.response.customers[0].externalCustomerdentifier",
                    ""
                );

                setCustomerID(customerExternalRef);
            } catch (error) {
                logger.error("error in create customer API", error);

                history.push(ROUTE_CONSTANTS.ERROR);
            }
        } else {
            setCustomerID(customerData?.customerExternalRef);
        }
    };

    const getCustomer = async () => {
        try {
            const email = sdkConfig.emailId;

            const preparePayload = {
                q: email,
                type: "email",
            };

            const { payload } = await getCustomerExists.invoke(preparePayload);

            logger.info("get Customer Exists API call successfull");

            dispatch(setCustomerExists(payload?.payload?.response));

            const customerData = get(payload, "payload.response", {});

            return customerData;
        } catch (error) {
            logger.error("Error in Customer Exists API:", error);

            history.push(ROUTE_CONSTANTS.ERROR);

            return false;
        }
    };

    const renderComponent = () => {
        return (
            <PaymentDetails
                setPaymentKeys={setPaymentKeys}
                paymentKeys={paymentKeys}
                authorizationSetUpIntent={`Bearer ${bearerToken}`}
                countryCode={countryCode}
                customerId={customerId}
                setError={setError}
                isError={isError}
                isApiError={isApiError}
                setIsApiError={setIsApiError}
                refreshComponent={refreshComponent}
                formData={formData}
                setFormData={setFormData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        );
    };

    return (
        <>
            {isComponentRefresh && <div className="ff-paid">{bearerToken && customerId && renderComponent()}</div>}
            {!isComponentRefresh && <div className="ff-paid">{bearerToken && customerId && renderComponent()}</div>}
        </>
    );
};

export default HealthReportPurchase;

HealthReportPurchase.propTypes = {};
