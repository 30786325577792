import logger from "common/utilities/logger";
import Prismic from "@prismicio/client";

class PrismicService {
    getDocWithUID = async (documentType, uid) => {
        try {
            const APIClient = Prismic.client("https://fetch-sales-funnel.cdn.prismic.io/api/v2");

            const response = await APIClient.getByUID(documentType, uid);

            logger.info("get prismic content successfully");

            return response;
        } catch (error) {
            logger.log("error in get prismic content");
            return error;
        }
    };

    getDocWithType = async (documentType) => {
        try {
            const defaultTemplate = await this.getDocWithUID(documentType, documentType);

            return defaultTemplate;
        } catch (error) {
            return error;
        }
    };
}

export default new PrismicService();
