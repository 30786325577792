import { commonActionType } from "../../actions";
import initialState from "../initialState";

export default function commonReducer(state = initialState.common, action) {
    switch (action.type) {
        case commonActionType.SHOW_LOADER:
            return {
                ...state,
                loadingIndicator: action.data,
            };
        case commonActionType.HIDE_LOADER:
            return {
                ...state,
                loadingIndicator: action.data,
            };
        default:
            return state;
    }
}
