import { getEnvVar } from "./ generic";

let parentWindow = null;

export function getPostMessage(callback) {
    callback = callback || function () {};
    let isContextSet = false;

    window.addEventListener(
        "message",
        function (event) {
            const origin = event?.origin || event?.originalEvent?.origin;
            let parentOrigin = document?.location?.ancestorOrigins?.length
                ? document?.location?.ancestorOrigins[0]
                : "";

            if (!parentOrigin) {
                parentOrigin = document.referrer;
                parentOrigin = parentOrigin.substring(0, parentOrigin.lastIndexOf("/")); // remove "/" at the end
            }

            if (origin === parentOrigin && !isContextSet && event?.data?.bearerToken) {
                parentWindow = event;
                callback(event.data);
                isContextSet = true;
            }
        },
        false
    );
}

export function sendPostMessage(event, data) {
    if (parentWindow?.source) {
        parentWindow.source.postMessage({ event, data }, parentWindow.origin);
    } else {
        const parent = window.top || window.parent;
        const targetHost = getEnvVar("REACT_APP_FRONTEND_HOST") || "*";
        parent && parent.postMessage({ event, data }, targetHost);
    }
}

export function removePostMessageListener(callback) {
    callback = callback || function () {};
    window.removeEventListener("message", callback);
}
